import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MainHeader from '../../Components/Headers/MainHeader';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';
import styles from './OpAllBatches.module.css';

const OpAllBatches = () => {
    const authCtx = useContext(AuthContext);
    const [allBatches, setAllBatches] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const opGetAllBatches = async () => {
        const opGetAllBatchesResponse = await fetch(BASE_URL_API + "/opGetAllBatches", {
            method: "POST",
            body: JSON.stringify({ token: authCtx.token }),
        });

        if (!opGetAllBatchesResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const opGetAllBatchesRespo = await opGetAllBatchesResponse.json();
            if (opGetAllBatchesRespo.status === "success") {
                setAllBatches(opGetAllBatchesRespo?.response);
            } else {
                if (opGetAllBatchesRespo?.message === "token Expired, please login again!") {
                    authCtx.logout();
                }
            }
        }
    };

    useEffect(() => {
        opGetAllBatches();
    }, []);

    const sortHandler = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });

        const sortedBatches = [...allBatches].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
        setAllBatches(sortedBatches);
    };

    const handleRowsPerPageChange = (e) => {
        setRowsPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to the first page when changing rows per page
    };

    const totalPages = Math.ceil(allBatches.length / rowsPerPage);
    const paginatedData = allBatches.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    return (
        <div>
            <MainHeader />
            <div className={styles.mainWrapper}>

                <div>
                    <div className={styles.headerRowWrapper}>
                        <div className={styles.batchIdentifier} onClick={() => sortHandler('batchIdentifier')}>
                            Batch ID
                        </div>
                        <div className={styles.totalOrders} onClick={() => sortHandler('totalOrders')}>
                            Total Orders
                        </div>
                        <div className={styles.createdOn} onClick={() => sortHandler('createdOn')}>
                            Created On
                        </div>
                        <div className={styles.createdOn}>
                            Time
                        </div>
                        <div className={styles.action}>
                            Action
                        </div>
                    </div>
                    <div className={styles.allRows}>
                        {paginatedData?.map((item, index) => (
                            <div
                                key={index}
                                className={`${styles.eachRowWrapper} ${styles.rowAnimation}`}
                            >
                                <div className={styles.batchIdentifier}>
                                    <Link to={`/marketplace/order-processing/batch/${item?.batchIdentifier}`}>
                                        {item?.batchIdentifier}
                                    </Link>
                                </div>
                                <div className={styles.totalOrders}>{item?.totalOrders}</div>
                                <div className={styles.createdOn}>
                                    {item?.createdOn?.substring(0, 10)}
                                </div>
                                <div className={styles.createdOn}>
                                    {item?.createdOn?.substring(11, 22)}
                                </div>
                                <div className={styles.action}>
                                    <Link to={`/marketplace/order-processing/batch/${item?.batchIdentifier}`}>
                                        View
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.paginationControls}>
                    <label>
                        Rows per page:
                        <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                        </select>
                    </label>
                    <div>
                        <button
                            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                        >
                            Previous
                        </button>
                        <span>
                            Page {currentPage} of {totalPages}
                        </span>
                        <button
                            onClick={() =>
                                setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                            }
                            disabled={currentPage === totalPages}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OpAllBatches;
