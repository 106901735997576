import React, { useContext, useEffect, useState } from 'react';
import { MdCheckBox, MdCheckBoxOutlineBlank, MdDelete } from 'react-icons/md';
import MainHeader from '../../Components/Headers/MainHeader';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';
import ClientSearch from './ClientSearch';
import styles from './GenerateInvoice.module.css';
import { v4 as uuidv4 } from "uuid"; // Use UUID to generate unique keys
import ProductSearch from './ProductSearch';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { motion } from "motion/react"
import { useParams } from 'react-router';



const GenerateInvoice = () => {
    const { invoiceIdentifier } = useParams()
    const authCtx = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [allProducts, setAllProducts] = useState([]);
    const [allClients, setAllClients] = useState([]);
    const [clientGstState, setClientGstState] = useState('Delhi')
    const [invoiceDataMaster, setInvoiceDataMaster] = useState({
        invoiceIdentifier : invoiceIdentifier,
        clientData: {
            clientName: '',
            phoneNumber: '',
            address: '',
            gst: '',
        },
        productData: [
            {
                id: uuidv4(),
                qty: 1
            }
        ],
        totalAmount: 0,
        isShippingAddressSame: true,
        shipmentPlaceOfSupply: 'Delhi',
        shipmentAddressData: {
        },
        discountType: 'percentage', /// value
        tncData: [
            {

            }
        ],
        customFields: [
            {}
        ]
    });
    const [clientSearch, setClientSearch] = useState('');
    const [filteredClients, setFilteredClients] = useState([]);
    const [gstSlabs, setGstSlabs] = useState([
        0, 0.1, 0.25, 0.5, 1, 1.5, 3, 5, 6, 7.5, 12, 18, 28
    ])

    const sectionVariants = {
        hidden: { opacity: 0, y: 50 }, // Start off-screen and transparent
        visible: { opacity: 1, y: 0 }, // Final state (fully visible and in-place)
    };

    const getInvoiceData = async () => {
        const getInvoiceDataResponse = await fetch(BASE_URL_API+"/getInvoiceData",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                invoiceIdentifier : invoiceIdentifier
            })
        });

        if(!getInvoiceDataResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getInvoiceDataRespo = await getInvoiceDataResponse.json()
            if(getInvoiceDataRespo?.status==="success") {
                updateInvoiceFields("invoiceNumber", getInvoiceDataRespo?.response?.invoiceNumber)
            } else {

            }
            console.log(getInvoiceDataRespo)
        }
    }

    useEffect(() => {
        getInvoiceData()
    }, [invoiceIdentifier])
    

    const getEcomProducts = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(`${BASE_URL_API}/getEcomProducts`, {
                method: 'POST',
                body: JSON.stringify({ token: authCtx.token }),
            });
            const data = await response.json();
            if (data.status === 'success') {
                setAllProducts(data.response);
            } else if (data.message === 'token Expired, please login again!') {
                authCtx.logout();
            }
        } catch (error) {
            console.error('Error fetching products:', error);
        }
        setIsLoading(false);
    };

    const getAllClients = async () => {
        try {
            const response = await fetch(`${BASE_URL_API}/getAllClients`, {
                method: 'POST',
                body: JSON.stringify({ token: authCtx.token }),
            });
            const data = await response.json();
            if (data.status === 'success') {
                setAllClients(data.response);
            } else if (data.message === 'token Expired, please login again!') {
                authCtx.logout();
            }
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    useEffect(() => {
        getEcomProducts();
        getAllClients();
    }, []);

    useEffect(() => {
        setFilteredClients(allClients);
    }, [allClients]);

    const handleClientSearch = (e) => {
        const search = e.target.value;
        setClientSearch(search);
        setFilteredClients(
            allClients.filter((client) =>
                client.clientName.toLowerCase().includes(search.toLowerCase())
            )
        );
    };

    const addProductRow = () => {
        setInvoiceDataMaster((prevState) => ({
            ...prevState,
            productData: [
                ...prevState.productData,
                { id: uuidv4(), productIdentifier: "", qty: 1, price: 0, total: 0 },
            ],
        }));
    };

    const removeProductRow = (id) => {
        setInvoiceDataMaster((prevState) => ({
            ...prevState,
            productData: prevState.productData.filter((row) => row.id !== id),
        }));
    };

    const updateProductRow = (id, field, value) => {
        setInvoiceDataMaster((prevState) => {
            const updatedProducts = prevState.productData.map((product) => {
                if (product.id === id) {
                    // Update the specific field
                    const updatedProduct = {
                        ...product,
                        [field]: value,
                    };

                    // Safely handle numeric fields with default values
                    const qty = Number(updatedProduct?.qty) || 0;
                    const price = Number(updatedProduct?.price) || 0;
                    const discount = Number(updatedProduct?.discount) || 0;
                    const gst = Number(updatedProduct?.gst) || 0;

                    // Calculate the base total
                    const baseTotal = qty * price;

                    // Handle discount based on discountType
                    let discountAmount = 0;
                    const discountType = prevState.discountType || 'value'; // Default to 'value'
                    if (discountType === 'percentage') {
                        discountAmount = (baseTotal * discount) / 100;
                    } else if (discountType === 'value') {
                        discountAmount = discount;
                    }

                    // Ensure discount does not exceed base total
                    discountAmount = Math.min(discountAmount, baseTotal);

                    // Update total after applying discount
                    const subtotal = baseTotal - discountAmount;

                    // Calculate GST if applicable
                    let gstAmount = 0;
                    if (gst > 0) {
                        gstAmount = (subtotal * gst) / 100;
                    }

                    // Update the product with calculated values
                    updatedProduct.total = subtotal + gstAmount;
                    updatedProduct.gstValue = gstAmount;

                    return updatedProduct;
                }
                return product; // Keep other products unchanged
            });

            // Calculate the total amount for all products
            const totalAmount = updatedProducts.reduce((acc, product) => acc + (product.total || 0), 0);

            return {
                ...prevState,
                productData: updatedProducts,
                totalAmount,
            };
        });
    };


    const handleSelectClient = (client) => {
        setInvoiceDataMaster((prevData) => ({
            ...prevData,
            clientData: {
                clientIdentifier: client.clientIdentifier,
                clientName: client.firmName,
                phoneNumber: client.phoneNumber,
                address: client.officeAddress,
                gst: client.gstNumber,
            },
        }));
    };

    const handleProductSelection = (id) => (product) => {
        handleSelectProduct(id, product);
    };

    const handleSelectProduct = (id, product) => {
        console.log(id, product)
        // Validate input parameters
        if (!id || typeof id !== "string") {
            console.error("Invalid or missing 'id' parameter");
            return;
        }
        if (!product || typeof product !== "object") {
            console.error("Invalid or missing 'product' parameter");
            return;
        }

        setInvoiceDataMaster((prevData) => {
            if (!prevData || !Array.isArray(prevData.productData)) {
                console.error("Invalid 'productData' in state");
                return prevData; // Return unchanged state if it's invalid
            }

            const updatedProductData = prevData.productData.map((item) => {
                // Only update the matching item, retain existing fields if values are undefined
                if (item.id === id) {
                    return {
                        ...item,
                        productIdentifier: product.productIdentifier ?? item.productIdentifier,
                        productTitle: product.productTitle ?? item.productTitle,
                        productDescription: product.productDescription ?? item.productDescription,
                        pointsRequired: product.pointsRequired ?? item.pointsRequired,
                        hsn: product.hsn ?? item.hsn,
                        price: product.price ?? item.price,
                        stock: product.stock ?? item.stock,
                        brandName: product.brandName ?? item.brandName,
                        category: product.category ?? item.category,
                        subCategory: product.subCategory ?? item.subCategory,
                        weight: product.weight ?? item.weight,
                        height: product.height ?? item.height,
                        width: product.width ?? item.width,
                        depth: product.depth ?? item.depth,
                        warranty: product.warranty ?? item.warranty,
                        guarantee: product.guarantee ?? item.guarantee,
                        coveredInWarranty: product.coveredInWarranty ?? item.coveredInWarranty,
                        notCoveredInWarranty: product.notCoveredInWarranty ?? item.notCoveredInWarranty,
                        images: product.images ?? item.images,
                    };
                }
                return item; // Return unchanged item if no match
            });

            return {
                ...prevData,
                productData: updatedProductData,
            };
        });
    };

    // Function to update individual fields in clientData
    const updateClientDataField = (field, value) => {
        setInvoiceDataMaster((prev) => ({
            ...prev,
            clientData: {
                ...prev.clientData,
                [field]: value,
            },
        }));
    };

    const updateInvoiceFields = (field, value) => {
        setInvoiceDataMaster((prevState) => {
            // Update the main field (e.g., discountType)
            const updatedState = {
                ...prevState,
                [field]: value,
            };

            if (field === 'isShippingAddressSame') {
                updateSameShipmentAddress()
            }

            // Trigger recalculation for all productData
            if (field === 'discountType') {
                updatedState.productData = prevState.productData.map((product) => {
                    const baseTotal = product.qty * product.price;

                    // Recalculate discount amount based on the new discountType
                    let discountAmount = 0;
                    if (value === 'percentage') {
                        discountAmount = (baseTotal * (product.discount || 0)) / 100;
                    } else if (value === 'value') {
                        discountAmount = product.discount || 0;
                    }

                    // Recalculate total after applying discount
                    let total = baseTotal - discountAmount;

                    // Recalculate GST if applicable
                    let gstValue = 0;
                    if (product.gst) {
                        gstValue = (total * product.gst) / 100;
                        total += gstValue;
                    }

                    return {
                        ...product,
                        total,
                        gstValue,
                    };
                });

                // Update totalAmount after recalculations
                updatedState.totalAmount = updatedState.productData.reduce(
                    (acc, product) => acc + (product.total || 0),
                    0
                );
            }

            return updatedState;
        });
    };

    const updateSameShipmentAddress = () => {
        setInvoiceDataMaster((prev) => ({
            ...prev,
            shipmentAddressData: {
                ...prev.shipmentAddressData,
                shipTo: invoiceDataMaster?.clientData?.firmName,
                shipmentName: invoiceDataMaster?.clientData?.clientName,
                shipmentAddress: invoiceDataMaster?.clientData?.address,
                shipmentPhone: invoiceDataMaster?.clientData?.phoneNumber,
                shipmentGST: invoiceDataMaster?.clientData?.gst
            }
        }))
    }

    const updateShipmentField = (field, value) => {
        setInvoiceDataMaster((prev) => ({
            ...prev,
            shipmentAddressData: {
                ...prev.shipmentAddressData,
                [field]: value
            }
        }))
    }

    const getProductItemsTotal = (key) => {
        // Validate the input parameters
        if (!key || typeof key !== "string") {
            console.error("Invalid key parameter. Please provide a valid string key.");
            return 0;
        }
        if (!Array.isArray(invoiceDataMaster?.productData)) {
            console.error("Invalid data parameter. Please provide an array of objects.");
            return 0;
        }

        // Calculate the total
        return invoiceDataMaster?.productData.reduce((total, item) => {
            const value = item[key];
            if (typeof value === "number" && !isNaN(value)) {
                return total + value;
            }
            return total; // Ignore non-numeric or missing values
        }, 0);
    }

    const updateTncRow = (id, field, value) => {
        setInvoiceDataMaster((prevState) => {
            const updatedTncData = prevState.tncData.map((item) =>
                item.id === id ? { ...item, [field]: value } : item
            );

            return {
                ...prevState,
                tncData: updatedTncData,
            };
        });
    };

    const addNewTncRow = () => {
        setInvoiceDataMaster((prevState) => {
            const newTncRow = {
                id: crypto.randomUUID(), // Generate a unique ID
                title: "",
                detail: "",
            };

            return {
                ...prevState,
                tncData: [...prevState.tncData, newTncRow],
            };
        });
    };

    const deleteTncRow = (id) => {
        setInvoiceDataMaster((prevState) => {
            const updatedTncData = prevState.tncData.filter((item) => item.id !== id);

            return {
                ...prevState,
                tncData: updatedTncData,
            };
        });
    };


    ///// final calculations.. 
    const calculateTotalTaxable = () => {
        return invoiceDataMaster?.productData?.reduce((acc, product) => {
            const price = Number(product?.price) || 0; // Ensure price is a number
            const qty = Number(product?.qty) || 1; // Ensure quantity is a number
            const discount = Number(product?.discount) || 0; // Ensure discount is a number
            const discountType = invoiceDataMaster?.discountType || 'value'; // Default to 'value'

            let finalDiscount = 0;

            // Calculate discount based on type
            if (discountType === "percentage") {
                // Calculate percentage discount per unit
                finalDiscount = (price * discount) / 100;
            } else if (discountType === "value") {
                // Fixed discount per unit (not multiplied by quantity)
                finalDiscount = discount;
            } else {
                console.log('error in discount type')
            }

            // Calculate the price after discount for one unit
            const priceAfterDiscount = price - finalDiscount;

            // Ensure priceAfterDiscount is not negative (could be 0 or positive)
            const taxablePrice = priceAfterDiscount * qty;

            // Accumulate the total taxable price
            return acc + Math.max(0, taxablePrice);
        }, 0);
    };





    const calculateTotalTax = () => {
        if (!invoiceDataMaster?.productData?.length) return 0;

        return invoiceDataMaster.productData.reduce((acc, product) => {
            const price = product?.price || 0;
            const qty = product?.qty || 1;
            const discount = product?.discount || 0;
            const discountType = invoiceDataMaster?.discountType || 'value';
            const gstRate = product?.gst || 0;

            // Calculate discounted price based on type
            let discountedPrice = price;
            if (discountType === 'percentage') {
                discountedPrice = price - ((price * discount) / 100);
            } else {
                discountedPrice = price - discount;
            }

            // Ensure discounted price is not negative
            discountedPrice = Math.max(0, discountedPrice);

            // Calculate total taxable amount for this product
            const taxablePrice = discountedPrice * qty;

            // Calculate tax for the product
            const tax = (parseFloat(gstRate) / 100) * taxablePrice;

            return acc + tax;
        }, 0);
    };



    const addCustomField = () => {
        setInvoiceDataMaster((prevState) => ({
            ...prevState,
            customFields: [
                ...prevState.customFields,
                {
                    id: crypto.randomUUID(), // Generate unique ID
                    fieldTitle: "",
                    calculationType: "value", // Default to value
                    calculationLogic: "add", // Default to add
                    value: 0,
                },
            ],
        }));
    };

    const removeCustomField = (id) => {
        setInvoiceDataMaster((prevState) => ({
            ...prevState,
            customFields: prevState.customFields.filter((field) => field.id !== id),
        }));
    };

    const updateCustomField = (id, field, value) => {
        setInvoiceDataMaster((prevState) => ({
            ...prevState,
            customFields: prevState.customFields.map((fieldItem) =>
                fieldItem.id === id ? { ...fieldItem, [field]: value } : fieldItem
            ),
        }));
    };


    const toggleCalculationType = (id) => {
        setInvoiceDataMaster((prevState) => ({
            ...prevState,
            customFields: prevState.customFields.map((field) =>
                field.id === id
                    ? { ...field, calculationType: field.calculationType === "value" ? "percentage" : "value" }
                    : field
            ),
        }));
    };
    const toggleCalculationLogic = (id) => {
        setInvoiceDataMaster((prevState) => ({
            ...prevState,
            customFields: prevState.customFields.map((field) =>
                field.id === id
                    ? { ...field, calculationLogic: field.calculationLogic === "add" ? "subtract" : "add" }
                    : field
            ),
        }));
    };
    const roundOffHandler = () => {
        setInvoiceDataMaster((prevState) => {
            // Safely handle grandTotal
            const grandTotal = Number(prevState.grandTotal) || 0;

            // Calculate the round-off value
            const roundOffValue = Math.round(grandTotal) - grandTotal;

            return {
                ...prevState,
                isRoundingOff: !prevState.isRoundingOff,
                roundOffValue: !prevState.isRoundingOff ? roundOffValue : 0, // Toggle round-off value
            };
        });
    };


    const calculateGrandTotal = () => {
        let grandTotal = calculateTotalTaxable() + calculateTotalTax();

        invoiceDataMaster?.customFields?.forEach((field) => {
            const fieldValue =
                field.calculationType === "percentage"
                    ? (grandTotal * (field.value || 0)) / 100
                    : field.value || 0;
            grandTotal += field.calculationLogic === "add" ? fieldValue : -fieldValue;
        });

        if (invoiceDataMaster?.isRoundingOff) {
            grandTotal = Math.round(grandTotal);
        }

        return grandTotal;
    };

    const convertNumberToText = (amount) => {
        if (isNaN(amount) || amount < 0) return "Invalid amount";

        const units = [
            "", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"
        ];
        const teens = [
            "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen",
            "Seventeen", "Eighteen", "Nineteen"
        ];
        const tens = [
            "", "Ten", "Twenty", "Thirty", "Forty", "Fifty",
            "Sixty", "Seventy", "Eighty", "Ninety"
        ];
        const thousands = [
            "", "Thousand", "Lakh", "Crore"
        ];

        // Split rupees and paise
        const [rupeesPart, paisePart] = amount
            .toFixed(2) // Ensure two decimal places
            .split(".") // Split into rupees and paise
            .map(Number); // Convert strings to numbers

        const convertChunk = (num) => {
            let result = "";

            if (num > 99) {
                result += `${units[Math.floor(num / 100)]} Hundred `;
                num %= 100;
            }

            if (num > 10 && num < 20) {
                result += `${teens[num - 11]} `;
                num = 0;
            } else if (num >= 10) {
                result += `${tens[Math.floor(num / 10)]} `;
                num %= 10;
            }

            if (num > 0) {
                result += `${units[num]} `;
            }

            return result.trim();
        };

        const convertRupees = (num) => {
            let result = "";
            let level = 0;

            while (num > 0) {
                const chunk = num % 1000;

                if (chunk > 0) {
                    result = `${convertChunk(chunk)} ${thousands[level]} ${result}`.trim();
                }

                num = Math.floor(num / 1000);
                level++;
            }

            return result.trim();
        };

        const rupeesText = convertRupees(rupeesPart) || "Zero";
        const paiseText = convertChunk(paisePart);

        if (paisePart > 0) {
            return `${rupeesText} Rupees and ${paiseText} Paise Only`.trim();
        }

        return `${rupeesText} Rupees Only`.trim();
    };


    const updatePaymentMode = (selectedMode) => {
        // Update the paymentMode field in invoiceDataMaster
        setInvoiceDataMaster((prev) => ({
            ...prev,
            paymentMode: selectedMode,
        }));
    };

    const saveInvoiceHandler = () => {
        console.log(invoiceDataMaster)
    }



    return (
        <div>
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    {/* Client Information */}
                    <div className={styles.firstRow}>
                        <motion.div className={styles.clientPanel}
                            initial="hidden"
                            animate="visible"
                            variants={sectionVariants}
                            transition={{ duration: 0.5, delay: 0.1, ease: "easeOut" }}
                        >
                            <div className={styles.panelHeader}>
                                <div>Client Information</div>
                            </div>
                            <div className={styles.panelBody}>
                                <div>
                                    <ClientSearch allClients={allClients} onSelectClient={handleSelectClient} />
                                </div>
                                <div>
                                    <div className={styles.eachInputWrapper}>
                                        <div className={styles.inputTitleWrapper}>
                                            <div className={styles.inputTitle}>Address</div>
                                        </div>
                                        <div className={styles.inputFieldWrapper}>
                                            <textarea
                                                value={invoiceDataMaster.clientData.address}
                                                onChange={(e) => updateClientDataField("address", e.target.value)}
                                                placeholder="Enter address..."
                                                rows={2}
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.twoInARow}>
                                        <div className={styles.eachInputWrapper}>
                                            <div className={styles.inputTitleWrapper}>
                                                <div className={styles.inputTitle}>Contact Person</div>
                                            </div>
                                            <div className={styles.inputFieldWrapper}>
                                                <input
                                                    value={invoiceDataMaster.clientData.contactPerson}
                                                    onChange={(e) =>
                                                        updateClientDataField("contactPerson", e.target.value)
                                                    }
                                                    placeholder="Please enter contact person name..."
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.eachInputWrapper}>
                                            <div className={styles.inputTitleWrapper}>
                                                <div className={styles.inputTitle}>Contact Person Phone</div>
                                            </div>
                                            <div className={styles.inputFieldWrapper}>
                                                <input
                                                    value={invoiceDataMaster.clientData.phoneNumber}
                                                    onChange={(e) =>
                                                        updateClientDataField("phoneNumber", e.target.value)
                                                    }
                                                    placeholder="Please enter contact person phone..."
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.eachInputWrapper}>
                                        <div className={styles.inputTitleWrapper}>
                                            <div className={styles.inputTitle}>GST Details</div>
                                        </div>
                                        <div className={styles.inputFieldWrapper}>
                                            <input
                                                value={invoiceDataMaster.clientData.gst}
                                                onChange={(e) => updateClientDataField("gst", e.target.value)}
                                                placeholder="Please enter GST number..."
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.eachInputWrapper}>
                                        <div className={styles.inputTitleWrapper}>
                                            <div className={styles.inputTitle}>Place of Supply</div>
                                        </div>
                                        <div className={styles.inputFieldWrapper}>
                                            <input
                                                value={invoiceDataMaster?.shipmentPlaceOfSupply}
                                                onChange={(e) => updateInvoiceFields("shipmentPlaceOfSupply", e.target.value)}
                                                placeholder="Please enter place of supply"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>

                        <motion.div className={styles.clientPanel}
                            initial="hidden"
                            animate="visible"
                            variants={sectionVariants}
                            transition={{ duration: 0.5, delay: 0.2, ease: "easeOut" }}
                        >
                            <div className={styles.panelHeader}>
                                <div>Shipping Information</div>
                            </div>
                            <div className={styles.panelBody}>
                                <div className={styles.shippingAddress}>
                                    <div >
                                        Shipping Address
                                    </div>
                                    <div className={styles.shippingIcon} onClick={() => updateInvoiceFields('isShippingAddressSame', !invoiceDataMaster?.isShippingAddressSame)} >
                                        {invoiceDataMaster?.isShippingAddressSame === true ?
                                            <MdCheckBox size={20} />
                                            :
                                            <MdCheckBoxOutlineBlank size={20} />
                                        }
                                        Use same shipping address
                                    </div>
                                </div>
                                {invoiceDataMaster?.isShippingAddressSame === false &&
                                    <div>
                                        <div className={styles.eachInputWrapper}>
                                            <div className={styles.inputTitleWrapper}>
                                                <div className={styles.inputTitle}>Ship To</div>
                                            </div>
                                            <div className={styles.inputFieldWrapper}>
                                                <input
                                                    value={invoiceDataMaster?.shipmentAddressData?.shipTo}
                                                    onChange={(e) => updateShipmentField("shipTo", e.target.value)}
                                                    placeholder="Please enter ship to..."
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.eachInputWrapper}>
                                            <div className={styles.inputTitleWrapper}>
                                                <div className={styles.inputTitle}>Ship. Name</div>
                                            </div>
                                            <div className={styles.inputFieldWrapper}>
                                                <input
                                                    value={invoiceDataMaster?.shipmentAddressData?.shipmentName}
                                                    onChange={(e) => updateShipmentField("shipmentName", e.target.value)}
                                                    placeholder="Please enter shipment name..."
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.eachInputWrapper}>
                                            <div className={styles.inputTitleWrapper}>
                                                <div className={styles.inputTitle}>Ship. Address</div>
                                            </div>
                                            <div className={styles.inputFieldWrapper}>
                                                <input
                                                    value={invoiceDataMaster?.shipmentAddressData?.shipmentAddress}
                                                    onChange={(e) => updateShipmentField("shipmentAddress", e.target.value)}
                                                    placeholder="Please enter shipment address..."
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.eachInputWrapper}>
                                            <div className={styles.inputTitleWrapper}>
                                                <div className={styles.inputTitle}>Ship. Phone</div>
                                            </div>
                                            <div className={styles.inputFieldWrapper}>
                                                <input
                                                    value={invoiceDataMaster?.shipmentAddressData?.shipmentPhone}
                                                    onChange={(e) => updateShipmentField("shipmentPhone", e.target.value)}
                                                    placeholder="Please enter shipment phone..."
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.eachInputWrapper}>
                                            <div className={styles.inputTitleWrapper}>
                                                <div className={styles.inputTitle}>Ship. GSTIN</div>
                                            </div>
                                            <div className={styles.inputFieldWrapper}>
                                                <input
                                                    value={invoiceDataMaster?.shipmentAddressData?.shipmentGST}
                                                    onChange={(e) => updateShipmentField("shipmentGST", e.target.value)}
                                                    placeholder="Please enter GST..."
                                                />
                                            </div>
                                        </div>


                                    </div>
                                }
                            </div>

                        </motion.div>

                        {/* Invoice Settings */}
                        <motion.div className={styles.invoiceDetailsPanel}
                            initial="hidden"
                            animate="visible"
                            variants={sectionVariants}
                            transition={{ duration: 0.5, delay: 0.3, ease: "easeOut" }}
                        >
                            <div className={styles.panelHeader}>
                                Invoice Settings
                            </div>
                            <div className={styles.panelBody}>
                                {/* Add fields for PO Number, Challan No, etc. */}
                                <div className={styles.eachInputWrapper}>
                                    <div className={styles.inputTitleWrapper}>
                                        <div className={styles.inputTitle}>Invoice Type</div>
                                    </div>
                                    <div className={styles.inputFieldWrapper}>
                                        <select
                                            onChange={(e) => updateInvoiceFields("invoiceType", e.target.value)}
                                            value={invoiceDataMaster?.invoiceType}
                                        >
                                            <option>Regular</option>
                                            <option>Bill of Supply</option>
                                        </select>
                                    </div>
                                </div>

                                <div className={styles.twoInARow}>
                                    <div className={styles.eachInputWrapper}>
                                        <div className={styles.inputTitleWrapper}>
                                            <div className={styles.inputTitle}>Invoice #</div>
                                        </div>
                                        <div className={styles.inputFieldWrapper}>
                                            <input
                                                value={invoiceDataMaster.invoiceNumber}
                                                onChange={(e) =>
                                                    updateInvoiceFields("invoiceNumber", e.target.value)
                                                }
                                                placeholder="Please enter invoice number..."
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.eachInputWrapper}>
                                        <div className={styles.inputTitleWrapper}>
                                            <div className={styles.inputTitle}>Invoice Date</div>
                                        </div>
                                        <div className={styles.inputFieldWrapper}>
                                            <input
                                                type="date"
                                                value={invoiceDataMaster.invoiceDate}
                                                onChange={(e) =>
                                                    updateInvoiceFields("invoiceDate", e.target.value)
                                                }
                                                placeholder="Please enter invoice date..."
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.twoInARow}>
                                    <div className={styles.eachInputWrapper}>
                                        <div className={styles.inputTitleWrapper}>
                                            <div className={styles.inputTitle}>Challan #</div>
                                        </div>
                                        <div className={styles.inputFieldWrapper}>
                                            <input
                                                value={invoiceDataMaster.challanNumber}
                                                onChange={(e) =>
                                                    updateInvoiceFields("challanNumber", e.target.value)
                                                }
                                                placeholder="Please enter challan number..."
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.eachInputWrapper}>
                                        <div className={styles.inputTitleWrapper}>
                                            <div className={styles.inputTitle}>Challan Date</div>
                                        </div>
                                        <div className={styles.inputFieldWrapper}>
                                            <input
                                                type="date"
                                                value={invoiceDataMaster.challanDate}
                                                onChange={(e) =>
                                                    updateInvoiceFields("challanDate", e.target.value)
                                                }
                                                placeholder="Please enter challan date..."
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.twoInARow}>
                                    <div className={styles.eachInputWrapper}>
                                        <div className={styles.inputTitleWrapper}>
                                            <div className={styles.inputTitle}>PO #</div>
                                        </div>
                                        <div className={styles.inputFieldWrapper}>
                                            <input
                                                value={invoiceDataMaster.poNumber}
                                                onChange={(e) =>
                                                    updateInvoiceFields("poNumber", e.target.value)
                                                }
                                                placeholder="Please enter PO number..."
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.eachInputWrapper}>
                                        <div className={styles.inputTitleWrapper}>
                                            <div className={styles.inputTitle}>PO Date</div>
                                        </div>
                                        <div className={styles.inputFieldWrapper}>
                                            <input
                                                type="date"
                                                value={invoiceDataMaster.poDate}
                                                onChange={(e) =>
                                                    updateInvoiceFields("poDate", e.target.value)
                                                }
                                                placeholder="Please enter PO date..."
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.twoInARow}>
                                    <div className={styles.eachInputWrapper}>
                                        <div className={styles.inputTitleWrapper}>
                                            <div className={styles.inputTitle}>LR #</div>
                                        </div>
                                        <div className={styles.inputFieldWrapper}>
                                            <input
                                                value={invoiceDataMaster.lrNumber}
                                                onChange={(e) =>
                                                    updateInvoiceFields("lrNumber", e.target.value)
                                                }
                                                placeholder="Please enter LR number..."
                                            />
                                        </div>
                                    </div>

                                    <div className={styles.eachInputWrapper}>
                                        <div className={styles.inputTitleWrapper}>
                                            <div className={styles.inputTitle}>E-way Number</div>
                                        </div>
                                        <div className={styles.inputFieldWrapper}>
                                            <input
                                                value={invoiceDataMaster.ewayNumber}
                                                onChange={(e) =>
                                                    updateInvoiceFields("ewayNumber", e.target.value)
                                                }
                                                placeholder="Please enter E-Way number..."
                                            />
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </motion.div>
                    </div>
                    {/* Products */}
                    <div className={styles.secondRow}>
                        <motion.div className={styles.productsPanel}
                            initial="hidden"
                            animate="visible"
                            variants={sectionVariants}
                            transition={{ duration: 0.5, delay: 0.4, ease: "easeOut" }}
                        >
                            <div className={styles.productsHeader}>
                                <div className={styles.sn}>#</div>
                                <div className={styles.productItem}>Product / Item / Other Charges</div>
                                <div className={styles.hsnSac}>HSN / SAC</div>
                                <div className={styles.qty}>Qty</div>
                                <div className={styles.uom}>UOM</div>
                                <div className={styles.price}>Price(₹)</div>
                                <div className={styles.discount}>Discount
                                    <span onClick={() => updateInvoiceFields('discountType', invoiceDataMaster?.discountType === 'value' ? 'percentage' : 'value')}>
                                        {invoiceDataMaster?.discountType === 'value' ? <div className={styles.discountIcon}>₹</div> : <div className={styles.discountIcon}>%</div>}
                                    </span>
                                </div>
                                <div className={styles.gst}>
                                    {invoiceDataMaster?.shipmentPlaceOfSupply === clientGstState ?
                                        "CGST + SGST"
                                        :
                                        "IGST"
                                    }
                                </div>
                                <div className={styles.cal}></div>
                                <div className={styles.total}>Total</div>
                            </div>
                            {invoiceDataMaster.productData.map((product, index) => (
                                <motion.div className={styles.productRow} key={index}
                                    initial={{ x: -100, opacity: 0 }} // Initial state (off-screen and transparent)
                                    animate={{ x: 0, opacity: 1 }}   // Final state (in-place and fully visible)
                                    exit={{ x: 100, opacity: 0 }}    // Exit state (slide out and transparent)
                                    transition={{ duration: 0.3, ease: "easeOut" }}
                                >
                                    <div className={styles.sn}>{index + 1}</div>
                                    <div className={styles.productItem}>
                                        <ProductSearch
                                            allRecords={allProducts}
                                            onSelectRecords={handleProductSelection(product?.id)}
                                        />
                                        <textarea placeholder="Item notes..." >

                                        </textarea>
                                    </div>
                                    <div className={styles.hsnSac}>
                                        <input
                                            type="number"
                                            value={product.hsn}
                                            onChange={(e) =>
                                                updateProductRow(product?.id, 'hsn', parseInt(e.target.value))
                                            }
                                        />
                                    </div>
                                    <div className={styles.qty}>
                                        <input
                                            type="number"
                                            value={product.qty}
                                            onChange={(e) =>
                                                updateProductRow(product?.id, 'qty', parseInt(e.target.value))
                                            }
                                        />
                                    </div>
                                    <div className={styles.uom}>
                                        <input
                                            type="number"
                                            value={product.uom}
                                            onChange={(e) =>
                                                updateProductRow(product?.id, 'uom', parseFloat(e.target.value))
                                            }
                                        />
                                    </div>
                                    <div className={styles.price}>
                                        <input
                                            type="number"
                                            value={product.price}
                                            onChange={(e) =>
                                                updateProductRow(product?.id, 'price', parseFloat(e.target.value))
                                            }
                                        />
                                    </div>
                                    <div className={styles.discount}>
                                        <input
                                            type="number"
                                            value={product.discount}
                                            onChange={(e) =>
                                                updateProductRow(product?.id, 'discount', parseFloat(e.target.value))
                                            }
                                        />
                                    </div>
                                    <div className={styles.gst}>
                                        <select
                                            value={product.gst}
                                            onChange={(e) =>
                                                updateProductRow(product?.id, 'gst', e.target.value)
                                            }
                                        >
                                            {gstSlabs?.map((itemGst, indexGst) => (
                                                <option key={indexGst}
                                                    value={itemGst}
                                                >
                                                    {invoiceDataMaster?.shipmentPlaceOfSupply === clientGstState ?
                                                        `${itemGst / 2} + ${itemGst / 2}`
                                                        :
                                                        `${itemGst}`
                                                    }
                                                </option>
                                            ))}
                                        </select>
                                        <textarea value={product?.gstValue}
                                            onChange={(e) =>
                                                updateProductRow(product?.id, 'gstValue', parseFloat(e.target.value))
                                            }
                                        >

                                        </textarea>
                                    </div>
                                    <div className={styles.cal}></div>
                                    <div className={styles.total}>
                                        {product?.total?.toFixed(2)}
                                    </div>
                                    <div className={styles.deleteRow}>
                                        <button onClick={() => removeProductRow(product?.id)} >
                                            <MdDelete size={20} />
                                        </button>
                                    </div>
                                </motion.div>
                            ))}
                            <div className={styles.itemsTotalWrapper}>
                                <div className={styles.totalInvoiceValue}>
                                    Total Invoice Value
                                </div>
                                <div className={styles.hsnSac}></div>
                                <div className={styles.qty}>
                                    {/* show here sum of all quantities... */}
                                    {getProductItemsTotal("qty")}
                                </div>
                                <div className={styles.uom}>

                                </div>
                                <div className={styles.price}>
                                    {/* show here sum of all prices... */}
                                    {getProductItemsTotal("price")?.toFixed(2)}
                                </div>
                                <div className={styles.discount}>
                                    {/* show here sum of all discounts... */}
                                    {getProductItemsTotal("discount")?.toFixed(2)}
                                </div>
                                <div className={styles.gst}>
                                    {/* show here sum of all gstValues... */}
                                    {getProductItemsTotal("gstValue")?.toFixed(2)}
                                </div>
                                <div className={styles.cal}></div>
                                <div className={styles.total}>
                                    {/* show here sum of all total... */}
                                    {getProductItemsTotal("total")?.toFixed(2)}
                                </div>
                            </div>
                            <div className={styles.addNewRow}>
                                <button onClick={addProductRow} className={styles.addRowButton}>
                                    Add Product
                                </button>
                            </div>
                        </motion.div>
                    </div>
                    
                    {/* Billing Information */}
                    <div className={styles.thirdRow}>
                        <motion.div className={styles.billingPanel}
                            initial="hidden"
                            animate="visible"
                            variants={sectionVariants}
                            transition={{ duration: 0.5, delay: 0.5, ease: "easeOut" }}
                        >
                            <div className={styles.bpContainer} >
                                <div className={styles.bpLeft}>
                                    <div className={styles.eachBpField} >
                                        <div className={styles.twoInARow}>
                                            <div className={styles.eachInputWrapper}>
                                                <div className={styles.inputTitleWrapper}>
                                                    <div className={styles.inputTitle}>Due Date</div>
                                                </div>
                                                <div className={styles.inputFieldWrapper}>
                                                    <input
                                                        type="date"
                                                        value={invoiceDataMaster.dueDate}
                                                        onChange={(e) =>
                                                            updateInvoiceFields("dueDate", e.target.value)
                                                        }
                                                        placeholder="Please enter due date..."
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.eachInputWrapper}>
                                                <div className={styles.inputTitleWrapper}>
                                                    <div className={styles.inputTitle}>Bank Details</div>
                                                </div>
                                                <div className={styles.inputFieldWrapper}>
                                                    <select
                                                        onChange={(e) => updateInvoiceFields("invoiceType", e.target.value)}
                                                        value={invoiceDataMaster?.invoiceType}
                                                    >
                                                        <option>Hide Bank Details</option>
                                                        <option>ICICI Bank</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.tncWrapper}>
                                        <div className={styles.tncHeader} >
                                            <div>
                                                Terms & Conditions / Additional Notes
                                            </div>
                                            <div className={styles.addNewBtn} >
                                                <button onClick={addNewTncRow}>Add New</button>
                                            </div>
                                        </div>
                                        <div className={styles.tncBody}>
                                            {invoiceDataMaster?.tncData?.map((itemTnc, indexTnc) => (
                                                <div className={styles.eachTncWrapper} key={itemTnc.id}>
                                                    <div className={styles.eachInputWrapper}>
                                                        <div className={styles.inputTitleWrapper}>
                                                            <div className={styles.inputTitle}>Title</div>
                                                        </div>
                                                        <div className={styles.inputFieldWrapper}>
                                                            <input
                                                                // autoFocus
                                                                value={itemTnc.title}
                                                                onChange={(e) => updateTncRow(itemTnc.id, "title", e.target.value)}
                                                                placeholder="Please enter title..."
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={styles.eachInputWrapper}>
                                                        <div className={styles.inputTitleWrapper}>
                                                            <div className={styles.inputTitle}>Detail</div>
                                                        </div>
                                                        <div className={styles.inputFieldWrapper}>
                                                            <textarea
                                                                value={itemTnc.detail}
                                                                onChange={(e) => updateTncRow(itemTnc.id, "detail", e.target.value)}
                                                                placeholder="Please enter detail..."
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={styles.rowAction}>
                                                        <button onClick={addNewTncRow}><FaPlus size={15} /></button>
                                                        <button onClick={() => deleteTncRow(itemTnc.id)}>
                                                            <MdDelete size={20} />
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>

                                    <div className={styles.documentNotesWrapper}>
                                        <div className={styles.eachInputWrapper}>
                                            <div className={styles.inputTitleWrapper}>
                                                <div className={styles.inputTitle}>Document Notes / Remarks</div>
                                            </div>
                                            <div className={styles.inputFieldWrapper}>
                                                <textarea
                                                    value={invoiceDataMaster.documentNotesRemarks}
                                                    onChange={(e) => updateInvoiceFields("documentNotesRemarks", e.target.value)}
                                                    placeholder="Please enter document notes and remarks..."
                                                >
                                                </textarea>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className={styles.bpRight}>
                                    <div className={styles.bpRightContainer}>
                                        {/* Taxable and Tax Rows */}
                                        <div className={styles.rightTwoFields}>
                                            <div className={styles.eachbpRightRow}>
                                                <div>Total Taxable</div>
                                                <div>{calculateTotalTaxable()?.toFixed(2)}</div>
                                            </div>
                                            <div className={styles.eachbpRightRow}>
                                                <div>Total Tax</div>
                                                <div>{calculateTotalTax()?.toFixed(2)}</div>
                                            </div>
                                        </div>

                                        {/* Custom Fields Section */}
                                        <div className={styles.customizedFields}>
                                            {invoiceDataMaster?.customFields?.map((itemCustom, indexCustom) => (
                                                <div className={styles.eachPaymentRow} key={indexCustom}>
                                                    <div className={styles.eprLeft}>
                                                        {/* Field Title Input */}
                                                        <div className={styles.customFieldInput}>
                                                            <input
                                                                placeholder="Enter name of field"
                                                                value={itemCustom?.fieldTitle}
                                                                onChange={(e) => updateCustomField(itemCustom.id, "fieldTitle", e.target.value)}
                                                            />
                                                        </div>

                                                        {/* Toggle for Calculation Type (Value/Percentage) */}
                                                        <div className={styles.percentRupeeToggle} onClick={() => toggleCalculationType(itemCustom.id)}>
                                                            <div className={itemCustom?.calculationType === "value" ? styles.activeToggle : styles.defToggle}>₹</div>
                                                            <div className={itemCustom?.calculationType !== "value" ? styles.activeToggle : styles.defToggle}>%</div>
                                                        </div>

                                                        {/* Toggle for Calculation Logic (Add/Subtract) */}
                                                        <div className={styles.percentRupeeToggle} onClick={() => toggleCalculationLogic(itemCustom.id)}>
                                                            <div className={itemCustom?.calculationLogic === "subtract" ? styles.activeToggle : styles.defToggle}><FaMinus size={11} /></div>
                                                            <div className={itemCustom?.calculationLogic !== "subtract" ? styles.activeToggle : styles.defToggle}><FaPlus size={11} /></div>
                                                        </div>

                                                        {/* Remove Field Button */}
                                                        <button onClick={() => removeCustomField(itemCustom.id)} className={styles.deleteIcon} >
                                                            <MdDelete size={20} color="purple" />
                                                        </button>
                                                    </div>

                                                    <div>
                                                        {/* Custom Value Input */}
                                                        <div className={styles.customFieldValue}>
                                                            <input
                                                                type="number"
                                                                placeholder="Enter value"
                                                                value={itemCustom?.value || ""}
                                                                onChange={(e) => updateCustomField(itemCustom.id, "value", Number(e.target.value))}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}

                                            <div className={styles.addBtnWrapper}>
                                                {/* Add New Field Button */}
                                                <button onClick={addCustomField}>Add New Field</button>
                                            </div>
                                        </div>

                                        {/* Round Off Section */}
                                        <div className={styles.roundOffWrapper}>
                                            <div className={styles.roundOffContainer}>

                                                <div className={styles.roundOffTxt} >
                                                    <div className={styles.roundOffTitle} >Round Off</div>
                                                    <div className={styles.roundOffToggle} onClick={roundOffHandler}>
                                                        <div className={invoiceDataMaster?.isRoundingOff === true ? styles.activeToggle : styles.defToggle}>Yes</div>
                                                        <div className={invoiceDataMaster?.isRoundingOff === false ? styles.activeToggle : styles.defToggle}>No</div>
                                                    </div>
                                                </div>
                                                <div>{invoiceDataMaster?.roundOffValue || 0}</div>
                                            </div>
                                        </div>

                                        {/* Grand Total Section */}
                                        <div className={styles.grandTotalWrapper}>
                                            <div className={styles.grandTotalContainer}>
                                                <div>Grand Total</div>
                                                <div>₹{calculateGrandTotal()?.toFixed(2)}</div>
                                            </div>
                                        </div>

                                        {/* Total in words  */}
                                        <div className={styles.totalsInWrapper}>
                                            <div className={styles.totalsInContainer}>
                                                <div className={styles.totalInWordsTitle} >Total In Words</div>
                                                <div className={styles.totalInWrodsValue}>
                                                    {convertNumberToText(calculateGrandTotal())}
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.paymentModeWrapper}>
                                            <div className={styles.paymentModeContainer}>
                                                <div>Payment Type</div>
                                                <div className={styles.paymentModes}>
                                                    {['Credit', 'Cash', 'Cheque', 'Online'].map((mode) => (
                                                        <div
                                                            key={mode}
                                                            className={
                                                                invoiceDataMaster?.paymentMode === mode
                                                                    ? styles.eachPModeActive
                                                                    : styles.eachPMode
                                                            }
                                                            onClick={() => updatePaymentMode(mode)}
                                                        >
                                                            {mode}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className={styles.thatsitRow}>
                                That's it!
                            </div>
                        </motion.div>
                    </div>

                    <div className={styles.gapRow}>

                    </div>
                </div>
                {/* Footer */}
                <div className={styles.footer}>
                    <div className={styles.totalAmount} >Total Amount: ₹{calculateGrandTotal()?.toFixed(2)}</div>
                    <div className={styles.activeBtnWrapper}>
                        <button className={styles.activeBtn}>Save & Print</button>
                        <button className={styles.activeBtn} onClick={() => saveInvoiceHandler()} >Save</button>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default GenerateInvoice;
