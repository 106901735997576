import React from 'react';
import { MdApi, MdCloud, MdSettings } from 'react-icons/md';
import { FaShippingFast } from 'react-icons/fa';
import { MdOutlineInsertComment } from 'react-icons/md';
import { Link } from 'react-router-dom';
import MainHeader from '../../Components/Headers/MainHeader';
import SettingsBreadcrumb from '../../Components/Settings/SettingsBreadcrumb';
import styles from './SettingsMain.module.css';

const SettingsMain = () => {
    return (
        <div>
            <MainHeader />
            <SettingsBreadcrumb />
            <div className={styles.mainContainer}>
                {/* Company Settings */}
                <div className={styles.sectionWrapper}>
                    <div className={styles.eachSectionWrapper}>
                        <div className={styles.sectionHeader}>
                            <div className={styles.sectionTitle}>
                                <MdSettings /> Company Settings
                            </div>
                            <div className={styles.sectionSubtitle}>
                                Configure your company's basic details, preferences, and overall settings for streamlined operations.
                            </div>
                        </div>
                    </div>

                    {/* API Credentials */}
                    <div className={styles.eachSectionWrapper}>
                        <Link to={`/settings/apis`} className={styles.sectionHeader}>
                            <div className={styles.sectionTitle}>
                                <MdApi /> API Credentials
                            </div>
                            <div className={styles.sectionSubtitle}>
                                Manage and secure your API integrations for seamless data flow across services.
                            </div>
                        </Link>

                        <div className={styles.sectionBody}>
                            {/* Logistic */}
                            <Link to={`/settings/apis/logistics`} className={styles.eachSubSectionWrapper}>
                                <div className={styles.subSectionTitle}>
                                    <FaShippingFast /> Logistic
                                </div>
                                <div className={styles.subSectionDescription}>
                                    Connect and manage logistics API integrations for order shipping and tracking.
                                </div>
                            </Link>

                            {/* Communication */}
                            <Link to={`/settings/apis/communications`} className={styles.eachSubSectionWrapper}>
                                <div className={styles.subSectionTitle}>
                                    <MdOutlineInsertComment /> Communication
                                </div>
                                <div className={styles.subSectionDescription}>
                                    Integrate SMS, email, and notification APIs for efficient communication with customers.
                                </div>
                            </Link>

                            {/* CDN Storage */}
                            <Link to={`/settings/apis/cdn-storage`} className={styles.eachSubSectionWrapper}>
                                <div className={styles.subSectionTitle}>
                                    <MdCloud /> CDN Storage
                                </div>
                                <div className={styles.subSectionDescription}>
                                    Manage Content Delivery Network (CDN) integrations for faster media and file delivery.
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettingsMain;
