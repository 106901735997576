import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MainHeader from '../../Components/Headers/MainHeader'
import EachPDS from '../../Components/PointsDistributionSystem/EachPDS'
import LaunchPDS from '../../Components/PointsDistributionSystem/LaunchPDS'
import styles from './PDSHome.module.css'

const PDSHome = () => {
    const [activeView, setActiveView] = useState('Detailed')
    const [selectedPDS, setSelectedPDS] = useState(null)
    const [isDetailsOpen, setIsDetailsOpen] = useState(false)
    const [isLaunchOpen, setIsLaunchOpen] = useState(false)
    const initiateCampaignLaunch = (k) => {
        setIsLaunchOpen(true)
        setSelectedPDS(k)
    }
    const pdsRange = [
        {
            pdsIdentifier: 'QRCODESCANNING',
            "title": "QR Code Scanning",
            "definition": "Points are allocated when users scan a QR code placed on products, receipts, displays, or advertisements.",
            "applicableFor": ["Buyers", "End Users", "Distributors", "Franchise Partners"],
            "logicAndRules": {
                "uniqueQR": "Each product or purchase has a unique QR code.",
                "scanValidation": "Points are awarded only if the QR code is valid and not previously scanned.",
                "pointMultipliers": "Standard points for regular QR codes. Bonus points for promotional or high-value QR codes.",
                "tierBasedAllocation": "Higher tiers of customers can earn bonus points for scanning QR codes.",
                "geoLocationRestriction": "Optional feature to restrict QR scans to specific locations."
            },
            "example": "Customer scans a QR code on a shampoo bottle, earning 10 points. A special promotional QR code earns them 50 bonus points."
        },
        {
            pdsIdentifier: 'DIRECTPOINT',
            "title": "Direct Points Allocation",
            "definition": "Direct manual allocation of points by the brand or system administrator.",
            "applicableFor": ["Sellers", "Distributors", "Franchise Partners", "End Users"],
            "logicAndRules": {
                "customAllocation": "Points can be customized based on performance, milestones, or achievements.",
                "predefinedLimits": "Administrators can allocate points up to a certain predefined threshold.",
                "specialRewards": "Brands can directly reward points for special events.",
                "errorRectification": "Points can be manually adjusted for system errors."
            },
            "example": "A seller achieves their monthly target. The administrator allocates 500 points as a performance reward."
        },
        {
            pdsIdentifier: 'BILLSCANNING',
            "title": "Bill Scanning",
            "definition": "Points are awarded when users upload or scan a purchase bill/receipt.",
            "applicableFor": ["End Users", "Buyers"],
            "logicAndRules": {
                "billValidation": "Points are awarded after validating the receipt details (store, amount, date). Duplicate submissions are blocked.",
                "valueBasedPoints": "Fixed points per bill or variable points based on bill value.",
                "tierBasedIncentives": "Premium members can earn additional points for bill scanning.",
                "submissionDeadline": "Receipts must be scanned/uploaded within a set time, e.g., 7 days."
            },
            "example": "A customer uploads a bill worth 100 and earns 10 points (1 point per ₹10 spent). If they’re a premium user, they earn 20 points."
        },
        {
            pdsIdentifier: 'PRODUCTSELLING',
            "title": "Product Selling Points",
            "definition": "Sellers or distributors earn points based on the sale of products.",
            "applicableFor": ["Sellers", "Distributors", "Franchise Partners"],
            "logicAndRules": {
                "perUnitPoints": "Points are allocated for each product sold.",
                "tierBasedPoints": "Higher points for premium or fast-moving products.",
                "performanceMilestones": "Bonus points are awarded on achieving sales targets.",
                "timeBoundCampaigns": "Additional points for sales during specific campaigns or festive periods.",
                "productCategoryBasedPoints": "Differentiated points for various product categories."
            },
            "example": "A seller earns 5 points for every regular product sold and 10 points for premium products. Achieving 100 units in a month earns an additional 1,000 bonus points."
        },
        {
            pdsIdentifier: 'PRODUCTPURCHASE',
            "title": "Product Purchase Points",
            "definition": "Points are allocated when customers purchase eligible products from authorized sellers.",
            "applicableFor": ["Buyers", "End Users"],
            "logicAndRules": {
                "eligibleProducts": "Only selected products qualify for points.",
                "pointsPerPrice": "Points are proportional to the product price.",
                "bundledProducts": "Special points for bundled or combo offers.",
                "promotionalProducts": "Double or triple points for products under promotions."
            },
            "example": "A customer purchases a ₹50 product and earns 50 points. A promotional product earns them 100 points instead."
        },
        {
            pdsIdentifier: 'REFERRALPROGRAM',
            "title": "Referral Program Points",
            "definition": "Points are earned when users refer others to the loyalty program or brand.",
            "applicableFor": ["Buyers", "End Users", "Franchise Partners", "Sellers"],
            "logicAndRules": {
                "successfulReferrals": "Points are awarded only if the referred person completes registration or makes their first purchase.",
                "referralTiers": "Points increase for more successful referrals.",
                "timeBoundReferrals": "Special bonus points for referrals during campaigns."
            },
            "example": "A customer refers a friend who registers and makes a ₹50 purchase. Both the customer and the friend earn 100 points."
        },
        {
            pdsIdentifier: 'MILESTONEACHIEVEMENTS',
            "title": "Milestone Achievements",
            "definition": "Points are awarded when users achieve predefined milestones.",
            "applicableFor": ["Sellers", "Distributors", "Buyers", "End Users"],
            "logicAndRules": {
                "performanceMilestones": "Achieving sales, purchase, or referral targets.",
                "activityMilestones": "Points for number of visits, scans, or purchases.",
                "specialAchievements": "Points for anniversaries, first purchases, or record-breaking performance."
            },
            "example": "A seller achieves a monthly sales target of ₹10,000 and earns 5,000 bonus points."
        },
        {
            pdsIdentifier: 'ENGAGEMENTPOINTS',
            "title": "Engagement Points",
            "definition": "Points are allocated for engaging with the brand online or offline.",
            "applicableFor": ["End Users", "Buyers"],
            "logicAndRules": {
                "onlineEngagement": "Points for liking, sharing, or reviewing products.",
                "surveyParticipation": "Points for completing surveys or feedback forms.",
                "eventParticipation": "Points for attending events, workshops, or webinars.",
                "gamifiedEngagement": "Points through brand-related games or quizzes."
            },
            "example": "A customer writes a product review on the app and earns 20 points."
        },
        {
            pdsIdentifier: 'BIRTHDAYANNIVERSARYPOINTS',
            "title": "Birthday/Anniversary Points",
            "definition": "Special points allocated to users on birthdays, registration anniversaries, or milestones.",
            "applicableFor": ["End Users", "Buyers", "Franchise Partners"],
            "logicAndRules": {
                "automaticAllocation": "Points are auto-credited on birthdays or anniversaries.",
                "bonusGifts": "Special rewards can accompany these points."
            },
            "example": "A customer receives 200 points as a birthday gift from the brand."
        },
        {
            pdsIdentifier: 'SUBSCRIPTIONBASEDPOINTS',
            "title": "Subscription-Based Points",
            "definition": "Points are awarded for purchasing subscriptions or memberships.",
            "applicableFor": ["Buyers", "End Users"],
            "logicAndRules": {
                "subscriptionTiers": "Points vary for basic, premium, or VIP subscriptions.",
                "recurringRewards": "Earn points monthly for ongoing subscriptions."
            },
            "example": "A premium subscriber earns 500 points per month."
        }
    ]

    const tableViewData =
        [
            {
                "Title": "QR Code Scanning",
                "ApplicableFor": "Buyers, End Users",
                "PointsLogic": "Points on scanning unique QR codes",
                "Example": "Scan QR code on product earns 10 points",
                "EstimatedROI": "High - Drives engagement, product discovery, and repeat purchases."
            },
            {
                "Title": "Direct Points Allocation",
                "ApplicableFor": "Sellers, Distributors, End Users",
                "PointsLogic": "Manual point allocation for achievements",
                "Example": "500 points for meeting monthly targets",
                "EstimatedROI": "Moderate - Improves performance and targets but depends on manual tracking."
            },
            {
                "Title": "Bill Scanning",
                "ApplicableFor": "Buyers, End Users",
                "PointsLogic": "Points proportional to bill value",
                "Example": "Upload ₹100 bill, earn 10 points",
                "EstimatedROI": "High - Increases transaction volume and brand loyalty."
            },
            {
                "Title": "Product Selling Points",
                "ApplicableFor": "Sellers, Distributors",
                "PointsLogic": "Points based on units sold",
                "Example": "5 points per unit sold",
                "EstimatedROI": "Very High - Boosts sales directly and incentivizes sellers."
            },
            {
                "Title": "Product Purchase Points",
                "ApplicableFor": "Buyers, End Users",
                "PointsLogic": "Points based on purchase value",
                "Example": "Buy ₹50 product, earn 50 points",
                "EstimatedROI": "High - Encourages larger purchases and frequent buying."
            },
            {
                "Title": "Referral Program Points",
                "ApplicableFor": "Buyers, End Users, Sellers",
                "PointsLogic": "Points for successful referrals",
                "Example": "Refer a friend, earn 100 points",
                "EstimatedROI": "Very High - Drives customer acquisition at a lower cost."
            },
            {
                "Title": "Milestone Achievements",
                "ApplicableFor": "Sellers, Distributors, Buyers",
                "PointsLogic": "Points on achieving milestones",
                "Example": "Reach ₹10,000 sales, earn 5,000 points",
                "EstimatedROI": "High - Improves performance and encourages goal completion."
            },
            {
                "Title": "Engagement Points",
                "ApplicableFor": "End Users, Buyers",
                "PointsLogic": "Points for brand engagement activities",
                "Example": "Write a review, earn 20 points",
                "EstimatedROI": "Moderate - Improves visibility and builds brand trust."
            },
            {
                "Title": "Birthday/Anniversary Points",
                "ApplicableFor": "End Users, Buyers",
                "PointsLogic": "Special points on birthdays or anniversaries",
                "Example": "Earn 200 points on birthday",
                "EstimatedROI": "Moderate - Enhances emotional connection and loyalty."
            },
            {
                "Title": "Subscription-Based Points",
                "ApplicableFor": "Buyers, End Users",
                "PointsLogic": "Points for subscribing to plans",
                "Example": "Premium membership earns 500 points monthly",
                "EstimatedROI": "High - Generates recurring revenue and long-term retention."
            }
        ]


    return (
        <div className={styles.mainWrapper}>
            <MainHeader />
            <EachPDS isOpen={isDetailsOpen} onClose={() => setIsDetailsOpen(false)} data={selectedPDS} initiateCampaignLaunch={initiateCampaignLaunch} />
            <LaunchPDS isOpen={isLaunchOpen} onClose={() => setIsLaunchOpen(false)} data={selectedPDS} />
            <div className={styles.mainContainer}>
                <div className={styles.mainHeader}>
                    <div className={styles.leftMenu}>
                        <div className={styles.pdsTitle}>
                            Points Distribution System
                        </div>
                        <div className={styles.viewActionWrapper}>
                            <span className={activeView === "Detailed" ? styles.eachViewActionActive : styles.eachViewAction} onClick={() => setActiveView('Detailed')} >Detailed View</span> |
                            <span className={activeView === "Table" ? styles.eachViewActionActive : styles.eachViewAction} onClick={() => setActiveView('Table')}> Table View</span>
                        </div>
                    </div>
                    <div className={styles.launched}>
                        <Link to={`/pds/launched`} >View Launched Campaign</Link>
                    </div>
                </div>
                <div className={styles.mainBody}>
                    {activeView === "Detailed" &&
                        <div className={styles.pdsRangeWrapper}>
                            {pdsRange?.map((item, index) => (
                                <div key={index} className={styles.eachWrapper} >
                                    <div className={styles.title}>
                                        {item?.title}
                                    </div>
                                    <div className={styles.epBody}>
                                        <div className={styles.definition}>
                                            {item?.definition}
                                        </div>
                                        <div className={styles.applicableFor}>
                                            {item?.applicableFor?.map((itemApp, indexApp) => (
                                                <span key={indexApp}>{itemApp}</span>
                                            ))}
                                        </div>
                                        <div className={styles.example}>
                                            {item?.example}
                                        </div>
                                    </div>
                                    <div className={styles.actionWrapper}>
                                        <button onClick={() => {
                                            setIsDetailsOpen(true)
                                            setSelectedPDS(item)
                                        }} >
                                            View Details
                                        </button>
                                        <button onClick={() => initiateCampaignLaunch(item)}>Launch</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    }
                    {activeView === "Table" &&
                        <div className={styles.pdsTableView}>
                            <div className={styles.tableHeader}>
                                <div className={styles.tTitle}>
                                    Title
                                </div>
                                <div className={styles.ApplicableFor}>
                                    Suitable For
                                </div>
                                <div className={styles.PointsLogic}>
                                    Points Logic
                                </div>
                                <div className={styles.Example}>
                                    Example
                                </div>
                                <div className={styles.EstimatedROI}>
                                    Estimated ROI
                                </div>
                            </div>
                            <div className={styles.tableRowsWrapper}>
                                {tableViewData?.map((item, index) => (
                                    <div key={index} className={styles.eachTableRow} >
                                        <div className={styles.tTitle}>
                                            {item?.Title}
                                        </div>
                                        <div className={styles.ApplicableFor}>
                                            {item?.ApplicableFor}
                                        </div>
                                        <div className={styles.PointsLogic}>
                                            {item?.PointsLogic}
                                        </div>
                                        <div className={styles.Example}>
                                            {item?.Example}
                                        </div>
                                        <div className={styles.EstimatedROI}>
                                            {item?.EstimatedROI}
                                        </div>

                                    </div>
                                ))}
                            </div>

                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default PDSHome