import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import AuthContext from '../../Store/auth-context'
import styles from './MainHeader.module.css'

const MainHeader = () => {
    const authCtx = useContext(AuthContext)
    const location = useLocation()



    return (
        <div className={styles.mainHeaderWrapper}>
            <div className={styles.upperRowHeader}>
                <div className={styles.leftWrapper}>
                    <Link to={`/home`}>
                        Home
                    </Link>
                    <Link to={`/kpis`}>
                        KPIs
                    </Link>
                </div>
                <div className={styles.rightWrapper}>
                    <button className={styles.logoutBtn} onClick={() => authCtx.logout()} >Logout</button>
                </div>
            </div>
            <div className={styles.lowRowHeader}>
                <div className={styles.lrhLeft}>
                    <Link className={location?.pathname === '/kpis' ? styles.eachNavActive : styles.eachNav} to={`/kpis`}>Dashboard</Link>
                    <Link className={location?.pathname === '/clients' ? styles.eachNavActive : styles.eachNav} to={`/clients`}>Clients</Link>
                    <Link className={location?.pathname === '/vendors' ? styles.eachNavActive : styles.eachNav} to={`/vendors`}>Vendors</Link>
                    <Link className={location?.pathname === '/marketplace/all-products' ? styles.eachNavActive : styles.eachNav} to={`/marketplace/all-products`}>Products</Link>
                    <Link className={location?.pathname === '/marketplace/bulk-orders' ? styles.eachNavActive : styles.eachNav} to={`/marketplace/bulk-orders`}>Orders</Link>
                    <Link className={location?.pathname === '/marketplace/invoices' ? styles.eachNavActive : styles.eachNav} to={`/marketplace/invoices`}>Sales Invoice</Link>
                    <Link className={location?.pathname === '/marketplace/payments' ? styles.eachNavActive : styles.eachNav} to={`/marketplace/payments`}>Payments</Link>
                </div>
                <div className={styles.lrhRight}>
                    <Link className={location?.pathname === '/settings' ? styles.eachNavActive : styles.eachNav} to={`/settings`}>Settings</Link>
                </div>
            </div>
        </div>
    )
}

export default MainHeader