import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import AddClientProducts from '../../Components/Clients/AddClientProducts';
import EachProductRow from '../../Components/Clients/EachProductRow';
import MainHeader from '../../Components/Headers/MainHeader';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';
import styles from './ClientProducts.module.css';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';

const ClientProducts = () => {
    const authCtx = useContext(AuthContext);
    const { clientIdentifier } = useParams();

    const [allProducts, setAllProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [loading, setLoading] = useState(false);

    const [isAddingNewProduct, setIsAddingNewProduct] = useState(false);
    const toggleAddingProduct = () => setIsAddingNewProduct(false);

    const getAllClientProducts = async () => {
        setLoading(true);
        const response = await fetch(BASE_URL_API + "/getAllClientProducts", {
            method: "POST",
            body: JSON.stringify({
                token: authCtx.token,
                clientIdentifier: clientIdentifier
            }),
        });

        if (!response.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const data = await response.json();
            if (data.status === "success") {
                setAllProducts(data.response);
                setFilteredProducts(data.response);
            } else {
                if (data.message === "token Expired, please login again!") {
                    authCtx.logout();
                } else {
                    setAllProducts([]);
                }
            }
            console.log(data)
        }
        setLoading(false);
    };

    useEffect(() => {
        getAllClientProducts();
    }, []);

    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);

        const filtered = allProducts.filter(
            (item) =>
                item.productTitle.toLowerCase().includes(value) ||
                item.productDescription.toLowerCase().includes(value) ||
                item.codeIdentifier.toLowerCase().includes(value) ||
                item.mrp.toString().includes(value)
        );

        setFilteredProducts(filtered);
    };

    const toggleRowSelection = (id) => {
        if (selectedRows.includes(id)) {
            setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows([...selectedRows, id]);
        }
    };

    const toggleSelectAll = () => {
        if (selectedRows.length === filteredProducts.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(filteredProducts.map((item) => item.productIdentifier));
        }
    };

    const handlePagination = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (e) => {
        setRowsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    const paginatedData = filteredProducts.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    return (
        <div>
            <AddClientProducts isOpen={isAddingNewProduct} toggleAddingProduct={toggleAddingProduct} onSuccess={getAllClientProducts} />
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.titleWrapper}>
                    <div className={styles.pageTitle} >View All Products</div>
                    <div className={styles.actionBtns}>
                        <button className={styles.addNewProduct} onClick={() => setIsAddingNewProduct(true)}>
                            Add New Product
                        </button>
                        <Link to={`/clients/${clientIdentifier}/products/bulk-upload`} className={styles.addProductInBulk}>
                            Add Product In Bulk
                        </Link>
                    </div>
                </div>
                <div className={styles.mainBodyWrapper}>
                    <div className={styles.mainActionWrapper}>
                        <div className={styles.filterWrapper}>
                            <input
                                type="text"
                                placeholder="Search products..."
                                value={searchTerm}
                                onChange={handleSearch}
                                className={styles.searchBox}
                            />
                        </div>
                    </div>
                    <div className={styles.tableHeader}>
                        <div className={styles.checkbox}
                            onChange={toggleSelectAll}
                        >
                            {selectedRows?.length === filteredProducts?.length ?
                                <MdCheckBox size={22} />
                                :
                                <MdCheckBoxOutlineBlank size={22} />
                            }
                        </div>
                        <div className={styles.sn} >#</div>
                        <div className={styles.productTitle} >Product ID</div>
                        <div className={styles.productTitle} >Product Title</div>
                        <div className={styles.productDescription} >Description</div>
                        <div className={styles.productTitle} >Brand</div>
                        <div className={styles.mrp} >MRP</div>
                        <div className={styles.mrp} >Action</div>
                    </div>
                    <div className={styles.tableBody}>
                        {loading ? (
                            <div className={styles.loader}>Loading...</div>
                        ) : (
                            paginatedData.map((item, index) => (
                                <EachProductRow
                                    key={item.productIdentifier}
                                    count={index + 1 + (currentPage - 1) * rowsPerPage}
                                    item={item}
                                    isSelected={selectedRows.includes(item.productIdentifier)}
                                    onSelect={() => toggleRowSelection(item.productIdentifier)}
                                />
                            ))
                        )}
                    </div>
                    <div className={styles.pagination}>
                        <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
                            <option value={5}>5 rows</option>
                            <option value={10}>10 rows</option>
                            <option value={20}>20 rows</option>
                        </select>
                        <div>
                            {Array.from(
                                { length: Math.ceil(filteredProducts.length / rowsPerPage) },
                                (_, i) => (
                                    <button
                                        key={i + 1}
                                        onClick={() => handlePagination(i + 1)}
                                        className={currentPage === i + 1 ? styles.activePage : ''}
                                    >
                                        {i + 1}
                                    </button>
                                )
                            )}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ClientProducts;
