import React, { useContext, useEffect, useState } from 'react';
import { FaShippingFast } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import MainHeader from '../../Components/Headers/MainHeader';
import SettingsBreadcrumb from '../../Components/Settings/SettingsBreadcrumb';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';
import styles from './SettingsAPIsLogistics.module.css';

const SettingsAPIsLogistics = () => {
    const authCtx = useContext(AuthContext)
    const [courierPartners, setCourierPartners] = useState(['Shiprocket', 'Express Bee', 'Delhivery']);
    const [records, setRecords] = useState([]);
    const [selectedPartner, setSelectedPartner] = useState('');
    const [formData, setFormData] = useState({ username: '', password: '' });
    const [editingIndex, setEditingIndex] = useState(null);

    // Handle dropdown selection
    const handlePartnerChange = (e) => {
        setSelectedPartner(e.target.value);
    };

    // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // Add or update record
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedPartner) {
            alert('Please select a courier partner');
            return;
        }

        const newRecord = { partner: selectedPartner, ...formData };

        if (editingIndex !== null) {
            // Update existing record
            const updatedRecords = [...records];
            updatedRecords[editingIndex] = newRecord;
            setRecords(updatedRecords);
            console.log('Updated Record:', newRecord);
        } else {
            // Add new record
            console.log('adding new...', selectedPartner)
            const addClientLogisticCredentialsResponse = await fetch(BASE_URL_API + "/addClientLogisticCredentials",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        logisticData: JSON.parse(selectedPartner),
                        username: newRecord?.username,
                        password: newRecord?.password
                    })
                });

            if (!addClientLogisticCredentialsResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const addClientLogisticCredentialsRespo = await addClientLogisticCredentialsResponse.json()
                if (addClientLogisticCredentialsRespo.status === "success") {
                    getAllClientLogisticPartners()
                    toast(`Successfully added the credentials!`)
                } else {
                    if (addClientLogisticCredentialsRespo.message === "token Expired, please login again!") {
                        authCtx.logout()
                    } else {

                    }
                }
                console.log(addClientLogisticCredentialsRespo)
            }

            setRecords((prev) => [...prev, newRecord]);
            console.log('New Record Added:', newRecord);
        }

        // Reset form
        setFormData({ username: '', password: '' });
        setSelectedPartner('');
        setEditingIndex(null);
    };

    // Delete a record
    const handleDelete = async (clientLogisticCredentialsIdentifier) => {
        if (window.confirm(`Are you sure you want to delete the account details?`)) {
            const deleteClientLogisticPartnersResponse = await fetch(BASE_URL_API + "/deleteClientLogisticPartners",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        clientLogisticCredentialsIdentifier: clientLogisticCredentialsIdentifier
                    })
                });

            if (!deleteClientLogisticPartnersResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const deleteClientLogisticPartnersRespo = await deleteClientLogisticPartnersResponse.json()
                if (deleteClientLogisticPartnersRespo.status === "success") {
                    toast('Successfully deleted the records')
                    getAllClientLogisticPartners()
                } else {
                    if (deleteClientLogisticPartnersRespo.message === "token Expired, please login again!") {
                        authCtx.logout()
                    } else {

                    }
                    console.log(deleteClientLogisticPartnersRespo)
                }
            }
        }


    };

    const getAllLogisticPartners = async () => {
        const getAllLogisticPartnersResponse = await fetch(BASE_URL_API + "/getAllLogisticPartners",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllLogisticPartnersResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllLogisticPartnersRespo = await getAllLogisticPartnersResponse.json()
            if (getAllLogisticPartnersRespo.status === "success") {
                setCourierPartners(getAllLogisticPartnersRespo?.response)
            } else {
                if (getAllLogisticPartnersRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setCourierPartners([])
                }
            }
            console.log(getAllLogisticPartnersRespo)
        }
    }

    const getAllClientLogisticPartners = async () => {
        const getAllClientLogisticPartnersResponse = await fetch(BASE_URL_API + "/getAllClientLogisticPartners",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllClientLogisticPartnersResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllClientLogisticPartnersRespo = await getAllClientLogisticPartnersResponse.json()
            if (getAllClientLogisticPartnersRespo.status === "success") {
                setRecords(getAllClientLogisticPartnersRespo?.response)
            } else {
                if (getAllClientLogisticPartnersRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setRecords([])
                }
            }
            console.log(getAllClientLogisticPartnersRespo)
        }
    }


    useEffect(() => {
        getAllLogisticPartners()
        getAllClientLogisticPartners()
    }, [])


    return (
        <div>
            <MainHeader />
            <SettingsBreadcrumb />
            <div className={styles.sectionBody}>
                <div className={styles.eachSubSectionWrapper}>
                    <div className={styles.subSectionTitle}>
                        <FaShippingFast /> Logistic API Credentials
                    </div>

                    {/* Form for adding/editing API credentials */}
                    <div className={styles.formWrapper}>
                        <form onSubmit={handleSubmit} className={styles.form}>
                            <select
                                value={selectedPartner}
                                onChange={handlePartnerChange}
                                required
                                className={styles.dropdown}
                            >
                                <option value="">Select Courier Partner</option>
                                {courierPartners.map((partner, index) => (
                                    <option key={index} value={JSON.stringify(partner)}>
                                        {partner?.partnerLabel}
                                    </option>
                                ))}
                            </select>
                            <input
                                type="text"
                                name="username"
                                placeholder="Username"
                                value={formData.username}
                                onChange={handleInputChange}
                                required
                                className={styles.inputField}
                            />
                            <input
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={formData.password}
                                onChange={handleInputChange}
                                required
                                className={styles.inputField}
                            />
                            <button type="submit" className={styles.submitButton}>
                                {editingIndex !== null ? 'Update' : 'Add'}
                            </button>
                        </form>
                    </div>

                    {/* Display added records */}
                    <div className={styles.recordsWrapper}>
                        {records?.map((record, index) => (
                            <div key={index} className={styles.record}>
                                <div className={styles.imageUrl} >
                                    <img src={record?.logoUrl} />
                                </div>
                                <div className={styles.recordInfo}>
                                    <strong>Partner:</strong> {record?.partnerLabel} <br />
                                    <strong>Username:</strong> {record.username}
                                </div>
                                <div className={styles.recordActions}>
                                    <Link
                                        to={`/settings/apis/logistics/${record?.clientLogisticCredentialsIdentifier}`}
                                        className={styles.editButton}
                                    >
                                        Edit
                                    </Link>
                                    <button
                                        onClick={() => handleDelete(record?.clientLogisticCredentialsIdentifier)}
                                        className={styles.deleteButton}
                                    >
                                        Delete
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettingsAPIsLogistics;
