import React, { useContext, useState } from 'react'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './EachProductRowForPlacingOrder.module.css'

const EachProductRowForPlacingOrder = (props) => {
    const { item, count, orderIdentifier, onSuccess, isAdded } = props
    const authCtx = useContext(AuthContext)
    const [isAdding, setIsAdding] = useState(false)
    const [hasAdded, setHasAdded] = useState(false)

    console.log(isAdded, item?.title)

    const addProductToOrderCart = async () => {
        console.log(orderIdentifier)
        setIsAdding(true)
        const addProductToOrderCartResponse = await fetch(BASE_URL_API + "/addProductToOrderCart",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    orderIdentifier: orderIdentifier,
                    item: item
                })
            });

        if (!addProductToOrderCartResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const addProductToOrderCartRespo = await addProductToOrderCartResponse.json()
            if (addProductToOrderCartRespo.status === "success") {
                onSuccess()
                setHasAdded(true)
            } else {
                if (addProductToOrderCartRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setHasAdded(false)
                }
            }
            console.log(addProductToOrderCartRespo)
        }
        setIsAdding(false)
    }


    return (
        <div className={styles.rowWrapper}>
            <div className={styles.sn}>{count + 1}</div>
            <div className={styles.thumbnail}>
                <img src={item?.thumbnail} />
            </div>
            <div className={styles.title}>{item?.productTitle}</div>
            <div className={styles.brand}>{item?.brandName}</div>
            <div className={styles.category}>{item?.category}</div>
            {hasAdded === false ?
                <div className={styles.action}>
                    {isAdding === true ?
                        <button >Adding...</button>
                        :
                        isAdded ?
                            `Added` :
                            <button onClick={() => addProductToOrderCart()} >Add To Cart</button>
                    }
                </div>
                :
                <div className={styles.action}>
                    Added
                </div>
            }
        </div>
    )
}

export default EachProductRowForPlacingOrder