import React, { useContext, useEffect, useState } from 'react'
import { MdCheckBox, MdCheckBoxOutlineBlank, MdError, MdForward, MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import MainHeader from '../../Components/Headers/MainHeader'
import ErrorTooltip from '../../Components/ManageShipping/ErrorTooltip'
import OpBatchShippingForwardActions from '../../Components/ManageShipping/OpBatchShippingForwardActions'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './OpBatchShipping.module.css'

const OpBatchShipping = () => {
    const authCtx = useContext(AuthContext)
    const { batchIdentifier } = useParams()
    const [allItems, setAllItems] = useState([])
    const [isListingItemForShipping, setIsListingItemForShipping] = useState(false)
    const [isListingPartners, setIsListingPartners] = useState(false)
    const [selectedItem, setSelectedItem] = useState([])
    const [allSelected, setAllSelected] = useState(false)
    const [estimatingCourierChargesStatus, setEstimatingCourierChargesStatus] = useState('NOTREQUESTED') // REQUESTING, REQUESTED
    const [isForwardActionOpen, setIsForwardActionOpen] = useState(false)

    const [logisticPartners, setLogisticPartners] = useState([])

    const listItemsForShipping = async () => {
        setIsListingItemForShipping(true)
        const listItemsForShippingResponse = await fetch(BASE_URL_API + "/listItemsForShipping",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    batchIdentifier: batchIdentifier
                })
            });

        if (!listItemsForShippingResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const listItemsForShippingRespo = await listItemsForShippingResponse.json()
            if (listItemsForShippingRespo.status === "success") {
                setAllItems(listItemsForShippingRespo?.response)
            } else {
                if (listItemsForShippingRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllItems([])
                }
            }
            console.log(listItemsForShippingRespo)
        }
        setIsListingItemForShipping(false)
    }

    useEffect(() => {
        // listItemsForShipping()
    }, [])

    const setLogisticPartner = (productIdentifier, logisticPartner, logisticPartnerIdentifier) => {
        console.log(productIdentifier, logisticPartner, logisticPartnerIdentifier)

        setAllItems((prevAllItems) =>
            prevAllItems.map((item) =>
                item.productIdentifier === productIdentifier
                    ? {
                        ...item,
                        logisticPartner: logisticPartner,
                        logisticPartnerIdentifier: logisticPartnerIdentifier, // Example: Set identifier based on partner
                    }
                    : item
            )
        );
    };

    const listAllClientsLogisticPartners = async () => {
        setEstimatingCourierChargesStatus('NOTREQUESTED')
        const getAllClientLogisticPartnersResponse = await fetch(BASE_URL_API + "/getAllClientLogisticPartners",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    cmClientIdentifier: 'QNNOX'
                })
            });

        if (!getAllClientLogisticPartnersResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllClientLogisticPartnersRespo = await getAllClientLogisticPartnersResponse.json()
            if (getAllClientLogisticPartnersRespo.status === "success") {
                setLogisticPartners(getAllClientLogisticPartnersRespo?.response)

            } else {
                if (getAllClientLogisticPartnersRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setLogisticPartners([])
                }
            }
            console.log(getAllClientLogisticPartnersRespo)
        }
    }

    const estimateCourierCharges = async () => {
        setEstimatingCourierChargesStatus('REQUESTING')
        const estimateCourierChargesResponse = await fetch(BASE_URL_API + "/estimateCourierCharges",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    allOrders: allItems,
                    logisticPartners: logisticPartners
                })
            });

        if (!estimateCourierChargesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const estimateCourierChargesRespo = await estimateCourierChargesResponse.json()
            if (estimateCourierChargesRespo.status === "success") {
                setAllItems(estimateCourierChargesRespo?.response)
            } else {
                if (estimateCourierChargesRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    console.log(estimateCourierChargesRespo)
                }
            }
        }
        setEstimatingCourierChargesStatus('REQUESTED')
    }

    const selectRowHandler = (item) => {
        if (selectedItem.some(selected => selected.productIdentifier === item.productIdentifier)) {
            // Remove the item if it's already selected
            setSelectedItem(selectedItem.filter(selected => selected.productIdentifier !== item.productIdentifier));
        } else {
            // Add the item if it's not selected
            setSelectedItem([...selectedItem, item]);
        }
    };

    // const selectAllHandler = () => {
    //     if (allSelected) {
    //         // Deselect all rows
    //         setSelectedItem([]);
    //         setAllSelected(false);
    //     } else {
    //         // Select all rows
    //         setSelectedItem([...allItems]); // `data` should be the array of all items
    //         setAllSelected(true);
    //     }
    // };

    const selectAllHandler = () => {
        if (allSelected) {
            // Deselect all rows
            setSelectedItem([]);
            setAllSelected(false);
        } else {
            // Select only rows where logisticPartnerIdentifier is truthy
            const filteredItems = allItems.filter(item => item.logisticPartnerIdentifier);
            setSelectedItem([...filteredItems]);
            setAllSelected(true);
        }
    };


    const forwardActionHandler = async () => {
        setIsForwardActionOpen(true)
    }


    const closeForwardActionHandler = () => {
        setIsForwardActionOpen(false)
    }


    console.log(allItems)
    // console.log(logisticPartners)

    return (
        <div>
            <MainHeader />
            {isForwardActionOpen && <OpBatchShippingForwardActions
                isOpen={isForwardActionOpen}
                close={closeForwardActionHandler}
                itemsToForward={selectedItem}
            />
            }
            <div>
                <div className={styles.mainHeader}>
                    <div className={styles.initBtn}>
                        {isListingItemForShipping === false ?
                            <button onClick={() => listItemsForShipping()}>List Item For Shipping </button>
                            :
                            <button>Please wait, Listing Items For Shipping...</button>
                        }
                        Batch Shipping
                    </div>
                    <div className={styles.actionBtns}>
                        <button className={styles.listPartnersBtn} onClick={() => listAllClientsLogisticPartners()} >List Logistic Partners</button>
                        {logisticPartners?.length > 0 ?
                            <button className={styles.listPartnersBtn} onClick={() => estimateCourierCharges()} >Estimate Courier Charges</button>
                            :
                            <button className={styles.listPartnersBtn} onClick={() => alert(`First list all couriers!`)} >Estimate Courier Charges</button>
                        }
                        {estimatingCourierChargesStatus === "REQUESTED" &&
                            <button
                                className={styles.courierBookingBtn}
                                disabled={selectedItem?.length === 0 && true}
                                title={selectedItem?.length > 0 ? `Click To Courier Order Booking` : `Select some orders to proceed!`}
                                onClick={() => forwardActionHandler()} >
                                <MdForward size={20} />
                                Forward Actions
                            </button>
                        }
                    </div>
                </div>
                <div className={styles.mainBody}>
                    <div className={styles.header}>
                        <div className={styles.checkboxBtn}
                            onClick={() => selectAllHandler()}
                        >
                            {allSelected ? (
                                <MdCheckBox size={22} />
                            ) : (
                                <MdCheckBoxOutlineBlank size={22} />
                            )}
                        </div>
                        <div className={styles.orderIdentifier}>Order #</div>
                        <div className={styles.productTitle}>Product Title</div>
                        <div className={styles.vendorIdentifier}>Vendor</div>
                        <div className={styles.logisticPartner}>Logistic Partners</div>
                        <div className={styles.currentStatus}>Status</div>
                    </div>
                    <div className={styles.rowsWrapper}>
                        {allItems?.map((item, index) => (
                            <div key={index} className={styles.eachItem} >
                                <div className={styles.checkboxBtn} onClick={() => selectRowHandler(item)}>
                                    {selectedItem.some(selected => selected.productIdentifier === item.productIdentifier) ? (
                                        <button>
                                            <MdCheckBox size={20} />
                                        </button>
                                    ) : (
                                        <button
                                            disabled={item?.logisticPartnerIdentifier === '' && true}
                                        >
                                            <MdCheckBoxOutlineBlank size={20} />
                                        </button>
                                    )}
                                </div>
                                <div className={styles.orderIdentifier} >{item?.orderIdentifier}</div>
                                <div className={styles.productTitle} >{item?.productTitle}</div>
                                <div className={styles.vendorIdentifier} >
                                    <Link to={`/vendor/${item?.vendorIdentifier}`}>
                                        {item?.warehouseName}
                                    </Link>
                                </div>
                                <div className={styles.logisticPartner}>
                                    {estimatingCourierChargesStatus !== "REQUESTED" ?
                                        <div className={styles.logisticPartnerContainer}>
                                            {estimatingCourierChargesStatus === "NOTREQUESTED" ? (
                                                <div className={styles.partnerList}>
                                                    {logisticPartners?.map((itemLp, indexLp) => (
                                                        <div key={indexLp} className={styles.partnerItem}>
                                                            {itemLp?.partnerLabel}
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div className={styles.loadingContainer}>
                                                    {logisticPartners?.map((itemLp, indexLp) => (
                                                        <div key={indexLp} className={styles.loadingItem}>
                                                            <span>Estimating charges for {itemLp?.partnerLabel}</span>
                                                            <div className={styles.spinner}></div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>

                                        :
                                        <div className={styles.logisticPartnerContainer}>
                                            {item?.logisticOptions?.map((itemLog, indexLog) => (
                                                <div
                                                    key={indexLog}
                                                    className={styles.eachLogisticPartner}
                                                    onClick={() => setLogisticPartner(item?.productIdentifier, itemLog?.logisticPartnerLabel, itemLog?.logisticPartnerIdentifier)}
                                                >
                                                    <div className={styles.eachLogisticPartnerWrapper}>
                                                        <div >
                                                            {item?.logisticPartnerIdentifier === itemLog?.logisticPartnerIdentifier ? (
                                                                <>
                                                                    <MdRadioButtonChecked size={20} />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <MdRadioButtonUnchecked size={20} />
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className={styles.partnerLogoWrapper}>
                                                            <img src={itemLog?.logoUrl} />
                                                        </div>
                                                        <div className={styles.partnerName}>
                                                            {itemLog?.logisticPartnerLabel}
                                                        </div>
                                                        <div className={styles.logisticError}>
                                                            {itemLog?.courierResponse?.message}
                                                        </div>
                                                        <div>
                                                            <ErrorTooltip itemLog={itemLog} />
                                                        </div>
                                                    </div>
                                                    <div className={styles.allCourierOptionsForEach}>
                                                        {itemLog?.courierResponse?.message === "" &&
                                                            <div className={styles.courierTableHeader}>
                                                                <div className={styles.courierSelection}>
                                                                    #
                                                                </div>
                                                                <div className={styles.ctCourierName}>
                                                                    Courier Name
                                                                </div>
                                                                <div className={styles.ctDeliveryTime} >
                                                                    Del. Time
                                                                </div>
                                                                <div className={styles.ctFreightCharges}>
                                                                    Freight
                                                                </div>
                                                                <div className={styles.ctCodCharges}>
                                                                    COD
                                                                </div>
                                                                <div className={styles.ctRate}>
                                                                    Rate
                                                                </div>
                                                            </div>
                                                        }
                                                        {itemLog?.logisticPartnerIdentifier === "S9KTXDQQJB1H4C9JT7TVGBC8E" &&
                                                            <div>
                                                                {itemLog?.courierResponse?.data?.available_courier_companies?.length > 0 && (
                                                                    itemLog.courierResponse.data?.available_courier_companies?.map((itemCourierResponse, indexCourierResponse) => (
                                                                        <div key={indexCourierResponse} className={styles.eachCourierData} >
                                                                            <div className={styles.courierSelection}>
                                                                                <MdRadioButtonChecked />
                                                                                <MdRadioButtonUnchecked />
                                                                            </div>
                                                                            <div className={styles.ctCourierName}>
                                                                                {itemCourierResponse?.courier_name || 'Unknown Courier'}
                                                                            </div>
                                                                            <div className={styles.ctDeliveryTime} >
                                                                                {itemCourierResponse?.estimated_delivery_days} days
                                                                            </div>
                                                                            <div className={styles.ctFreightCharges}>
                                                                                {itemCourierResponse?.freight_charge}
                                                                            </div>
                                                                            <div className={styles.ctCodCharges}>
                                                                                {itemCourierResponse?.cod_charges}
                                                                            </div>
                                                                            <div className={styles.ctRate}>
                                                                                ₹{parseInt(itemCourierResponse?.rate)}
                                                                            </div>

                                                                        </div>
                                                                    ))
                                                                )}
                                                            </div>
                                                        }
                                                        {itemLog?.logisticPartnerIdentifier === "EXSF6BH3434F445LM0EW5FD24" &&
                                                            <div>
                                                                {itemLog?.courierResponse?.data?.length > 0 && (
                                                                    itemLog.courierResponse.data?.map((itemCourierResponse, indexCourierResponse) => (
                                                                        <div key={indexCourierResponse} className={styles.eachCourierData} >
                                                                            <div className={styles.courierSelection}>
                                                                                <MdRadioButtonChecked />
                                                                                <MdRadioButtonUnchecked />
                                                                            </div>
                                                                            <div className={styles.ctCourierName}>
                                                                                {itemCourierResponse?.name || 'Unknown Courier'}
                                                                            </div>
                                                                            <div className={styles.ctDeliveryTime} >
                                                                                {itemCourierResponse?.estimated_delivery_days} -
                                                                            </div>
                                                                            <div className={styles.ctFreightCharges}>
                                                                                {itemCourierResponse?.freight_charges}
                                                                            </div>
                                                                            <div className={styles.ctCodCharges}>
                                                                                {itemCourierResponse?.cod_charges}
                                                                            </div>
                                                                            <div className={styles.ctRate}>
                                                                                ₹{parseInt(itemCourierResponse?.total_charges)}
                                                                            </div>

                                                                        </div>
                                                                    ))
                                                                )}
                                                            </div>
                                                        }
                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                                <div className={styles.currentStatus}>
                                    <div className={styles.currentStatusVal}>
                                        {item?.currentStatus}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OpBatchShipping