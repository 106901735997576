import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import EachClientRow from '../../Components/Clients/EachClientRow'
import MainHeader from '../../Components/Headers/MainHeader'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './AllClients.module.css'

const AllClients = () => {
    const authCtx = useContext(AuthContext)
    const [allClients, setAllClients] = useState([])
    const [searchKey, setSearchKey] = useState('')
    const [filteredData, setFilteredData] = useState([])


    const getAllClients = async () => {
        const getAllClientsResponse = await fetch(BASE_URL_API + "/getAllClients",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllClientsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllClientsRespo = await getAllClientsResponse.json();
            if (getAllClientsRespo.status === 'success') {
                setAllClients(getAllClientsRespo?.response)
                setFilteredData(getAllClientsRespo?.response)
            } else {
                if (getAllClientsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllClients([])
                    setFilteredData([])
                }
            }
        }
    }

    useEffect(() => {
        getAllClients()
    }, [])

    const filterData = (data, searchKey) => {
        setFilteredData(data.filter(item =>
            Object.values(item).some(value =>
                value?.toString().toLowerCase().includes(searchKey.toLowerCase())
            )
        ))
    }

    useEffect(() => {
        filterData(allClients, searchKey)
    }, [searchKey])
    


    return (
        <div>
            <MainHeader />
            <div className={styles.mainContentWrapper}>
                <div className={styles.filtersWrapper}>
                    <div>
                        All Clients
                    </div>
                    <div className={styles.rightWrapper}>
                        <div className={styles.search}>
                            <input placeholder="Search for anything..." value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
                        </div>
                        <div className={styles.addNew}>
                            <Link to={`/clients/onboard`}>Add New + </Link>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.tableHeader}>
                        <div className={styles.sn}>#</div>
                        <div className={styles.brandName}>Brand Name</div>
                        <div className={styles.firmName}>Firm Name</div>
                        <div className={styles.gst}>GST</div>
                        <div className={styles.cin}>State</div>
                        <div className={styles.createdOn}>Onboarded On</div>
                        <div className={styles.manageAuth}>Action</div>
                    </div>
                    <div className={styles.tableBody}>
                        {filteredData?.map((item, index) => (
                            <EachClientRow key={index} count={index} item={item} />
                        ))}
                        <div className={styles.thatsAll}>That's all</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllClients