import React, { useContext, useEffect, useState } from 'react'
import { BiSliderAlt } from 'react-icons/bi'
import { FaUsers } from 'react-icons/fa6'
import { IoIosList, IoMdSettings } from 'react-icons/io'
import { MdOutlineDescription } from 'react-icons/md'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import MainHeader from '../../Components/Headers/MainHeader'
import ELPDSConfig from '../../Components/PointsDistributionSystem/ELPDSConfig'
import ELPDSDetails from '../../Components/PointsDistributionSystem/ELPDSDetails'
import ELPDSProducts from '../../Components/PointsDistributionSystem/ELPDSProducts'
import ELPDSUsers from '../../Components/PointsDistributionSystem/ELPDSUsers'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './EachLaunchedPDS.module.css'

const EachLaunchedPDS = () => {
    const { pdsCampaignIdentifier } = useParams()
    const activeNav = window.location.pathname.split("/").at(-1)
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [campaignData, setCampaignData] = useState(null)
    const authCtx = useContext(AuthContext)

    const toggleMenuHandler = () => {
        setIsMenuOpen(!isMenuOpen)
    }



    const getLaunchedPdsDetails = async () => {
        const getLaunchedPdsDetailsResponse = await fetch(BASE_URL_API + "/getLaunchedPdsDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    pdsCampaignIdentifier: pdsCampaignIdentifier
                })
            });

        if (!getLaunchedPdsDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getLaunchedPdsDetailsRespo = await getLaunchedPdsDetailsResponse.json();
            if (getLaunchedPdsDetailsRespo.status === "success") {
                setCampaignData(getLaunchedPdsDetailsRespo.response)
            } else {
                if (getLaunchedPdsDetailsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setCampaignData(null)
                }
            }
            console.log(getLaunchedPdsDetailsRespo)
        }
    }

    useEffect(() => {
        if (pdsCampaignIdentifier !== null) {
            getLaunchedPdsDetails()
        }
    }, [pdsCampaignIdentifier])


    return (
        <div>
            <MainHeader />
            <div className={styles.mainContainer} >
                <div className={styles.mainContent}>
                    <div className={styles.leftWrapper}>
                        <div className={styles.schemeDetails}>
                            {isMenuOpen === true ?
                                <div>
                                    {campaignData?.pdsTitle}
                                </div>
                                :
                                <div>
                                    {campaignData?.pdsTitle?.split(" ")?.map(w => w[0])?.join("")}
                                </div>

                            }
                            <div className={styles.createdOn}>
                                Launched on {campaignData?.createdOn}
                            </div>
                            <div className={styles.brandName}>
                                Launched For {campaignData?.brandName}
                            </div>


                        </div>
                        <div className={styles.allNavigationOptions}>
                            <Link to={`/pds/${pdsCampaignIdentifier}/details`} className={activeNav === "Details" ? styles.eachNavOptionActive : styles.eachNavOption}
                            >
                                <MdOutlineDescription size={24} />
                                <div className={styles.navOptionTxt}>
                                    Details
                                </div>
                            </Link>
                            <Link to={`/pds/${pdsCampaignIdentifier}/users`} className={activeNav === "Users" ? styles.eachNavOptionActive : styles.eachNavOption}
                            >
                                <FaUsers size={24} />
                                <div className={styles.navOptionTxt}>
                                    Users
                                </div>
                            </Link>
                            <Link to={`/pds/${pdsCampaignIdentifier}/products`} className={activeNav === "Users" ? styles.eachNavOptionActive : styles.eachNavOption}
                            >
                                <IoIosList size={24} />
                                <div className={styles.navOptionTxt}>
                                    Products
                                </div>
                            </Link>

                            <Link to={`/pds/${pdsCampaignIdentifier}/config`} className={activeNav === "Config" ? styles.eachNavOptionActive : styles.eachNavOption}
                            >
                                <IoMdSettings size={24} />
                                <div className={styles.navOptionTxt}>
                                    Config
                                </div>
                            </Link>
                        </div>
                        <div className={styles.menuToggleWrapper}>
                            <div onClick={() => toggleMenuHandler()} className={styles.toggleBtn} >
                                <BiSliderAlt size={26} />
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.rightWrapper} ${isMenuOpen === true ? styles.small : styles.large}`}>
                        {activeNav === 'details' &&
                            <ELPDSDetails pdsCampaignIdentifier={pdsCampaignIdentifier} clientIdentifier={campaignData?.clientIdentifier} />
                        }
                        {activeNav === 'users' &&
                            <ELPDSUsers pdsCampaignIdentifier={pdsCampaignIdentifier} clientIdentifier={campaignData?.clientIdentifier} />
                        }
                        {activeNav === 'products' &&
                            <ELPDSProducts pdsCampaignIdentifier={pdsCampaignIdentifier} clientIdentifier={campaignData?.clientIdentifier} />
                        }
                        {activeNav === 'config' &&
                            <ELPDSConfig pdsCampaignIdentifier={pdsCampaignIdentifier} clientIdentifier={campaignData?.clientIdentifier} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EachLaunchedPDS