import React, { useContext, useState } from 'react'
import Papa from 'papaparse'; // CSV parsing library
import MainHeader from '../../Components/Headers/MainHeader'
import styles from './AddClientProductsInBulk.module.css'
import DownloadSampleSheet from '../../Components/Marketplace/DownloadSampleSheet';
import { FaNoteSticky } from "react-icons/fa6";
import { BsThreeDots } from "react-icons/bs";
import { MdOutlineCheckBoxOutlineBlank, MdOutlineCheckBox } from "react-icons/md";
import EditProductInBulkUploading from '../../Components/Marketplace/EditProductInBulkUploading';
import { v4 as uuidv4 } from 'uuid';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';

const AddClientProductsInBulk = () => {
    const authCtx = useContext(AuthContext)
    const { clientIdentifier } = useParams()
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const [editingField, setEditingField] = useState(null); // Tracks which cell is being edited
    const [editingItem, setEditingItem] = useState(null)
    const [isEditingItemOpen, setIsEditingItemOpen] = useState(false)
    const [editingRowIndex, setEditingRowIndex] = useState()
    const [showPreviewData, setShowPreviewData] = useState(false)
    const [selectedData, setSelectedData] = useState([])
    const rowSelectionHandler = (k) => {
        setSelectedData((prevSelectedData) =>
            prevSelectedData.some(item => item.uniqueId === k.uniqueId)
                ? prevSelectedData.filter(item => item.uniqueId !== k.uniqueId)
                : [...prevSelectedData, k]
        );
    };
    const [isUploading, setIsUploading] = useState(false)

    const handleAllSelector = () => {
        if (data.length === selectedData.length) {
            setSelectedData([])
        } else {
            setSelectedData(data)
        }
    }

    // Function to handle file upload
    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        if (file) {
            Papa.parse(file, {
                header: true, // CSV file has headers
                skipEmptyLines: true, // Skip empty lines
                complete: (result) => {
                    console.log('Total Rows Parsed:', result.data.length); // Log the total number of rows parsed
                    console.log('Parsed Data:', result.data); // Log the entire parsed data

                    const filteredData = result.data.map((row) => {
                        return {
                            uniqueId: uuidv4(),
                            // uniqueId : 34,
                            productTitle: row.productTitle || '',
                            productDescription: row.productDescription || '',
                            hsn: row.hsn || '',
                            mrp: row.mrp || '',
                            discount: row.discount || '',
                            modelNo: row.modelNo || '',
                            brandName: row.brandName || '',
                            category: row.category || '',
                            subCategory: row.subCategory || '',
                            weight: row.weight || '',
                            height: row.height || '',
                            width: row.width || '',
                            depth: row.depth || '',
                            warranty: row.warranty || '',
                            guarantee: row.guarantee || '',
                            coveredInWarranty: row.coveredInWarranty || '',
                            notCoveredInWarranty: row.notCoveredInWarranty || '',
                            img_url_1: row.img_url_1 || '',
                            img_url_2: row.img_url_2 || '',
                            img_url_3: row.img_url_3 || '',
                            img_url_4: row.img_url_4 || '',
                            img_url_5: row.img_url_5 || '',
                        };
                    });
                    setData(filteredData);
                    setShowPreviewData(true)
                },
                error: (err) => {
                    setError('Error parsing CSV file.');
                    console.error('Parsing error:', err);
                },
            });
        } else {
            setError('Please upload a valid CSV file.');
        }
    }

    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);


    const handleInputChange = (value, uniqueId, field) => {
        setData((prevData) =>
            prevData.map((item) =>
                item.uniqueId === uniqueId ? { ...item, [field]: value } : item
            )
        );
    };


    const startEditing = (uniqueId, field) => {
        setEditingField({ uniqueId, field });
    };


    const stopEditing = () => {
        setEditingField(null);
    };

    const renderCell = (row, uniqueId, field) => {
        const isEditing = editingField?.uniqueId === uniqueId && editingField?.field === field;

        return isEditing ? (
            <input
                type="text"
                value={row[field]}
                onChange={(e) => handleInputChange(e.target.value, uniqueId, field)}
                onBlur={stopEditing} // Stop editing when focus is lost
                autoFocus
            />
        ) : (
            <div onClick={() => startEditing(uniqueId, field)}>
                {row[field]}
            </div>
        );
    };





    const totalPages = Math.ceil(data.length / itemsPerPage);

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to the first page
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const visibleData = data.slice(startIndex, startIndex + itemsPerPage);


    const categoryData = [
        {
            "category": "Fashion & Accessories",
            "subCategory": ["Tshirts", "Shirts", "Pant", "Blazer", "Back Pack", "Trolley & Travel Bags", "Wallets", "Belts", "Pens", "Note Book", "Diary", "Jute Bag"]
        },
        {
            "category": "Electronic Accessories",
            "subCategory": ["Ear Pods", "Head Phones", "Head Sets", "Power Bank", "Projector", "Home Theatre", "Sound Bar", "Camera"]
        },
        {
            "category": "Appliances",
            "subCategory": ["TV", "Refrigerator", "Washing Machine", "Dryer", "Vaccum Cleaner", "Air Purifier", "Air Conditioner", "Water Heater", "Cofee Machine", "Microwave", "Air Cooler", "Fan"]
        },
        {
            "category": "Kitchen Appliances",
            "subCategory": ["Kettle", "Mixer Juicer", "Mixer Grinder", "Blender", "Food Processor", "Toaster", "OTG", "Dishwasher", "Chimney", "Induction", "Stove"]
        },
        {
            "category": "Furnitures",
            "subCategory": ["Table", "Chairs", "Sofa", "Bean Bag", "Shelves"]
        },
        {
            "category": "Sports Utilities",
            "subCategory": []
        },
        {
            "category": "Desktop",
            "subCategory": []
        }
    ]

    const itemEditingHandler = (k, indexOfRow) => {
        setEditingRowIndex(indexOfRow)
        setEditingItem(k)
        setIsEditingItemOpen(true)
    }
    const onCloseEditing = () => {
        setIsEditingItemOpen(false)
        // setEditingItem(null)
    }

    // Update product details
    const handleUpdateProduct = (updatedProduct) => {
        setData((prevProducts) =>
            prevProducts.map((product) =>
                product.uniqueId === updatedProduct?.uniqueId ? updatedProduct : product
            )
        );
        setIsEditingItemOpen(false); // Close modal after update
    };

    const updateFieldForSelected = (field, value) => {
        setData((prevData) =>
            prevData.map((row) =>
                selectedData.some((selectedRow) => selectedRow.uniqueId === row.uniqueId)
                    ? { ...row, [field]: value }
                    : row
            )
        );
    };


    const uploadProductInBulk = async () => {
        if (window.confirm(`Are you sure you want to upload ${selectedData.length} item?`)) {
            setIsUploading(true)
            const uploadProductInBulkResponse = await fetch(BASE_URL_API + "/uploadClientProductInBulk",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        clientIdentifier : clientIdentifier,
                        selectedData: selectedData
                    })
                });

            if (!uploadProductInBulkResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const uploadProductInBulkRespo = await uploadProductInBulkResponse.json()
                if (uploadProductInBulkRespo.status === "success") {
                    toast(`Product Uploaded successfully!`)
                } else {
                    if (uploadProductInBulkRespo.message === "token Expired, please login again!") {
                        authCtx.logout()
                    } else {

                    }
                }
                console.log(uploadProductInBulkRespo)
            }
            setIsUploading(false)
        }
    }


    return (
        <div className={styles.mainWrapper}>
            <MainHeader />
            {isEditingItemOpen === true &&
                <EditProductInBulkUploading
                    item={editingItem}
                    isOpen={isEditingItemOpen}
                    onClose={() => onCloseEditing()}
                    onUpdate={handleUpdateProduct}
                    categoryData={categoryData}
                />}

            <div className={styles.mainContainer}>
                <div className={styles.headerWrapper}>
                    <div className={styles.searchBar}>
                    </div>
                    <div className={styles.headerRightWrapper}>
                        <Link to={`/clients/${clientIdentifier}/all-products`} className={styles.addBtn}>
                            View All Client Products
                        </Link>
                    </div>
                </div>
                <div className={styles.uploadWrapper}>
                    <div className={styles.uploadContainer}>
                        <div className={styles.referenceWrapper}>
                            <div className={styles.guidelines}>
                                <h4>
                                    Add Products In Bulk
                                </h4>
                                <p>
                                    You can upload 100s of products in one go!
                                </p>
                                <p>
                                    Download the sample sheet, and start adding data there. Once finished, just save the file and upload it.
                                </p>
                                <p className={styles.notes}>
                                    Note : Do not change the file header (first row)
                                </p>
                            </div>
                            <div>
                                <DownloadSampleSheet />
                            </div>
                        </div>
                        <div className={styles.uploadBtnWrapper}>
                            <h2>Upload CSV File</h2>
                            {/* <input
                                type="file"
                                accept=".csv"
                                onChange={handleFileUpload}
                            /> */}
                            <div className={styles.customUploadButton}>
                                <label htmlFor="fileUpload">Choose File</label>
                                <input
                                    id="fileUpload"
                                    type="file"
                                    accept=".csv"
                                    onChange={handleFileUpload}
                                    className={styles.hiddenInput}
                                />
                            </div>
                        </div>
                    </div>
                    {error && <p style={{ color: 'red' }}>{error}</p>}

                    <div className={showPreviewData === true && styles.previewWrapper}>
                        <div className={styles.previewHeader}>
                            <button onClick={() => setShowPreviewData(false)} className={styles.cancelBtn} >Close </button>
                            <div>
                                <button onClick={() => updateFieldForSelected('category', 'Appliances')}>Update Category Appliances</button>
                                <button onClick={() => updateFieldForSelected('subCategory', 'Dryer')}>Update Category Appliances</button>
                            </div>
                            {selectedData?.length > 0 ?
                                isUploading === false ?
                                    <button className={styles.finalUploadBtn} onClick={() => uploadProductInBulk()} >
                                        Upload
                                    </button>
                                    :
                                    <button className={styles.finalUploadBtn} >
                                        Uploading Now...
                                    </button>
                                :
                                <button className={styles.finalUploadBtn} >
                                    Select Data to Upload
                                </button>
                            }
                        </div>
                        <div className={styles.previewContent}>

                            <div className={styles.previewContainer}>
                                {data.length > 0 && (
                                    <div className={styles.dataHolder}>
                                        {/* Fixed Table Header */}
                                        <div className={styles.tableHeaderWrapper}>
                                            <div className={styles.tableHeader}>
                                                <div className={`${styles.checkBox}`} onClick={() => handleAllSelector()} >
                                                    {data.length === selectedData.length ?
                                                        <MdOutlineCheckBox size={24} />
                                                        :
                                                        <MdOutlineCheckBoxOutlineBlank size={24} />
                                                    }
                                                </div>
                                                <div className={`${styles.productTitle}`}>
                                                    <div className={styles.cellValue}>
                                                        Product Details
                                                    </div>
                                                </div>
                                                <div className={`${styles.hsnCode}`}>
                                                    <div className={styles.cellValue}>
                                                        HSN Code
                                                    </div>
                                                </div>
                                                <div className={styles.mrp}>
                                                    <div className={styles.cellValue}>MRP</div>
                                                </div>
                                                <div className={styles.discount}>
                                                    <div className={styles.cellValue}>Discount</div>
                                                </div>
                                                <div className={styles.modelNo}>
                                                    <div className={styles.cellValue}>Model No.</div>
                                                </div>
                                                <div className={styles.brandName}>
                                                    <div className={styles.cellValue}>Brand Name</div>
                                                </div>
                                                <div className={styles.category}>
                                                    <div className={styles.cellValue}>Category</div>
                                                </div>
                                                <div className={styles.subCategory}>
                                                    <div className={styles.cellValue}>Sub Category</div>
                                                </div>
                                                <div className={styles.weight}>
                                                    <div className={styles.cellValue}>Weight (gms)</div>
                                                </div>
                                                <div className={styles.dimension}>
                                                    <div className={styles.cellValue}>Dimension (cm^3)</div>
                                                </div>
                                                <div className={styles.additionInfo}>
                                                    <div className={styles.cellValue}>Additional Info</div>
                                                </div>
                                                <div className={styles.actionWrapper}>
                                                    <div className={styles.cellValue}>Action</div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Table Body */}
                                        <div className={styles.tableBody}>
                                            {visibleData.map((row, index) => (
                                                <div key={index} className={styles.eachRowWrapper}>
                                                    <div className={styles.checkBox} onClick={() => rowSelectionHandler(row)}>
                                                        {selectedData.some((item) => item.uniqueId === row.uniqueId) ? (
                                                            <MdOutlineCheckBox size={24} />
                                                        ) : (
                                                            <MdOutlineCheckBoxOutlineBlank size={24} />
                                                        )}

                                                    </div>
                                                    <div className={styles.productTitle}>
                                                        <div className={styles.cellValue}>
                                                            {renderCell(row, row.uniqueId, 'productTitle')}
                                                        </div>
                                                    </div>
                                                    <div className={styles.hsnCode}>
                                                        <div className={styles.cellValue}>
                                                            {renderCell(row, row.uniqueId, 'hsn')}
                                                        </div>
                                                    </div>
                                                    <div className={styles.mrp}>
                                                        <div className={styles.cellValue}>
                                                            {renderCell(row, row.uniqueId, 'mrp')}
                                                        </div>
                                                    </div>
                                                    <div className={styles.discount}>
                                                        <div className={styles.cellValue}>
                                                            {renderCell(row, row.uniqueId, 'discount')}
                                                        </div>
                                                    </div>
                                                    <div className={styles.modelNo}>
                                                        <div className={styles.cellValue}>
                                                            {renderCell(row, row.uniqueId, 'modelNo')}
                                                        </div>
                                                    </div>
                                                    <div className={styles.brandName}>
                                                        <div className={styles.cellValue}>
                                                            {renderCell(row, row.uniqueId, 'brandName')}
                                                        </div>
                                                    </div>
                                                    <div className={styles.category}>
                                                        <div className={styles.cellValue}>
                                                            {editingField?.uniqueId === row?.uniqueId && editingField?.field === 'category' ? (
                                                                <select
                                                                    value={row.category}
                                                                    onChange={(e) => handleInputChange(e.target.value, row?.uniqueId, 'category')}
                                                                    onBlur={stopEditing}
                                                                    autoFocus
                                                                >
                                                                    <option>Select Category</option>
                                                                    {categoryData.map((cat, i) => (
                                                                        <option key={i} value={cat.category}>
                                                                            {cat.category}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            ) : (
                                                                <div onClick={() => startEditing(row?.uniqueId, 'category')}>
                                                                    {row.category || 'Select Category'}
                                                                </div>
                                                            )}
                                                        </div>

                                                    </div>
                                                    <div className={styles.subCategory}>
                                                        <div className={styles.cellValue}>
                                                            {editingField?.uniqueId === row?.uniqueId && editingField?.field === 'subCategory' ? (
                                                                <select
                                                                    value={row.subCategory}
                                                                    onChange={(e) =>
                                                                        handleInputChange(e.target.value, row?.uniqueId, 'subCategory')
                                                                    }
                                                                    onBlur={stopEditing}
                                                                    autoFocus
                                                                >
                                                                    <option>Select Sub Category</option>
                                                                    {categoryData
                                                                        .find((cat) => cat.category === row.category)
                                                                        ?.subCategory.map((sub, i) => (
                                                                            <option key={i} value={sub}>
                                                                                {sub}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                            ) : (
                                                                <div onClick={() => startEditing(row?.uniqueId, 'subCategory')}>
                                                                    {row.subCategory || 'Select Sub Category'}
                                                                </div>
                                                            )}
                                                        </div>

                                                    </div>
                                                    <div className={styles.weight}>
                                                        <div className={styles.cellValue}>
                                                            {/* {row.weight} */}
                                                            {renderCell(row, row.uniqueId, 'weight')}
                                                        </div>
                                                    </div>
                                                    <div className={styles.dimension}>
                                                        <div className={styles.cellValue}>
                                                            {renderCell(row, row.uniqueId, 'height')}
                                                            x
                                                            {renderCell(row, row.uniqueId, 'width')}
                                                            x
                                                            {renderCell(row, row.uniqueId, 'depth')}
                                                        </div>
                                                    </div>
                                                    <div className={styles.additionInfo}>
                                                        <div className={styles.cellValue}>
                                                            <div title={`Warranty : ${row.warranty}`}>
                                                                <FaNoteSticky size={24} />
                                                            </div>
                                                            <div title={`Guarantee : ${row.guarantee}`}>
                                                                <FaNoteSticky size={24} />
                                                            </div>
                                                            <div title={`Covered In Warranty : ${row.coveredInWarranty}`}>
                                                                <FaNoteSticky size={24} />
                                                            </div>
                                                            <div title={`Not Covered In Warranty : ${row.notCoveredInWarranty}`}>
                                                                <FaNoteSticky size={24} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={styles.actionWrapper}>
                                                        <div className={styles.cellValue}>
                                                            <button className={styles.moreWrapper} onClick={() => itemEditingHandler(row, index)} >
                                                                <BsThreeDots size={22} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* Footer */}
                            <div className={styles.footer}>
                                <div className={styles.footerTxt}>
                                    <label>Items per page: </label>
                                    <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                        {[50, 100, 150, 200, 250, 300, 350].map((count) => (
                                            <option key={count} value={count}>
                                                {count}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    Page {currentPage} of {totalPages}
                                </div>
                                <div>
                                    <button disabled={currentPage === 1} onClick={handlePreviousPage}>
                                        Previous
                                    </button>
                                    <button disabled={currentPage === totalPages} onClick={handleNextPage}>
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>
            </div>
        </div>
    )
}

export default AddClientProductsInBulk