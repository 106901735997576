import React, { useState, useEffect, useContext } from 'react';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import MainHeader from '../../Components/Headers/MainHeader';
import styles from './AllInvoices.module.css';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import CreateNewInvoice from '../../Components/Invoicing/CreateNewInvoice';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';

const AllInvoices = () => {
    const authCtx = useContext(AuthContext)
    const [allInvoices, setAllInvoices] = useState([]);
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [selectedRows, setSelectedRows] = useState([]);
    const [bulkSelect, setBulkSelect] = useState(false);

    const [isPopupOpen, setIsPopupOpen] = useState(false)

    useEffect(() => {
        getAllInvoices()
    }, []);

    const getAllInvoices = async () => {
        const getAllInvoicesResponse = await fetch(BASE_URL_API + "/getAllInvoices",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllInvoicesResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllInvoicesRespo = await getAllInvoicesResponse.json()
            if (getAllInvoicesRespo?.status === "success") {
                setAllInvoices(getAllInvoicesRespo?.response);
                setFilteredInvoices(getAllInvoicesRespo?.response);
            } else {
                setAllInvoices([])
                setFilteredInvoices([])
            }
            console.log(getAllInvoicesRespo)
        }
    }

    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = allInvoices.filter(
            (item) =>
                item.invoiceNumber.toLowerCase().includes(query.toLowerCase()) ||
                item.companyName.toLowerCase().includes(query.toLowerCase()) ||
                item.paymentType.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredInvoices(filtered);
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sorted = [...filteredInvoices].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
            return 0;
        });
        setFilteredInvoices(sorted);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    const handleRowSelection = (id) => {
        setSelectedRows((prev) =>
            prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
        );
    };

    const handleBulkSelection = () => {
        if (bulkSelect) {
            setSelectedRows([]);
        } else {
            const currentRows = filteredInvoices.slice(
                (currentPage - 1) * rowsPerPage,
                currentPage * rowsPerPage
            ).map((item) => item.id);
            setSelectedRows(currentRows);
        }
        setBulkSelect(!bulkSelect);
    };

    const paginatedInvoices = filteredInvoices.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const closePopUp = () => {
        setIsPopupOpen(false)
    }

    return (
        <div>
            <MainHeader />
            <CreateNewInvoice isOpen={isPopupOpen} onClose={() => closePopUp()} />
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}>
                    <div className={styles.filtersAndSearch}>
                        <input
                            type="text"
                            className={styles.searchInput}
                            placeholder="Search invoices..."
                            value={searchQuery}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                        <select
                            className={styles.rowsPerPageSelect}
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                        >
                            {[5, 10, 20, 50].map((number) => (
                                <option key={number} value={number}>
                                    {number} rows per page
                                </option>
                            ))}
                        </select>
                        <div className={styles.createNewInvoice} >
                            <button onClick={() => setIsPopupOpen(true)} >Create New Invoice</button>
                        </div>
                    </div>

                    <motion.div
                        className={styles.tableWrapper}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                    >
                        <div className={styles.tableHeader}>
                            <div className={styles.checkbox} onClick={handleBulkSelection}>
                                {bulkSelect ? <MdCheckBox size={20} /> : <MdCheckBoxOutlineBlank size={20} />}
                            </div>
                            {['invoiceNumber', 'companyName', 'date', 'total', 'paymentType', 'outstanding'].map(
                                (key) => (
                                    <div
                                        key={key}
                                        className={styles.columnHeader}
                                        onClick={() => handleSort(key)}
                                    >
                                        {key.replace(/([A-Z])/g, ' $1').toUpperCase()}
                                        {sortConfig.key === key && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
                                    </div>
                                )
                            )}
                            <div className={styles.action}>ACTION</div>
                        </div>

                        <div className={styles.tableBody}>
                            {paginatedInvoices.map((item, index) => (
                                <motion.div
                                    className={styles.eachRow}
                                    key={index}
                                    initial={{ y: 20, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <div
                                        className={styles.checkbox}
                                        onClick={() => handleRowSelection(item.id)}
                                    >
                                        {selectedRows.includes(item.id) ? <MdCheckBox size={20} /> : <MdCheckBoxOutlineBlank size={20} />}
                                    </div>
                                    <div className={styles.invoiceNumber}>{item.invoiceNumber}</div>
                                    <div className={styles.companyName}>{item.companyName}</div>
                                    <div className={styles.date}>{item.date}</div>
                                    <div className={styles.total}>{item.total}</div>
                                    <div className={styles.paymentType}>{item.paymentType}</div>
                                    <div className={styles.outstanding}>{item.outstanding}</div>
                                    <div className={styles.action}>
                                        <Link to={`/invoice/creating/${item?.invoiceIdentifier}`}>
                                            Edit / View
                                        </Link>
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    </motion.div>

                    <div className={styles.paginationWrapper}>
                        {Array.from(
                            { length: Math.ceil(filteredInvoices.length / rowsPerPage) },
                            (_, index) => (
                                <button
                                    key={index}
                                    className={`${styles.paginationButton} ${currentPage === index + 1 ? styles.activePage : ''
                                        }`}
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllInvoices;
