import React, { useContext } from 'react'
import { MdCheckBox, MdCheckBoxOutlineBlank, MdDelete, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './EachRowViewBulkOrder.module.css'


const EachRowViewBulkOrder = (props) => {
    const authCtx = useContext(AuthContext)
    const { item, count, selectedItems, rowSelectionHandler, getBulkOrders } = props

    const deleteOrderHandler = async (k) => {
        if(window.confirm(`Are you sure you want to delete this order?`)) {
            const deleteOrderResponse = await fetch(BASE_URL_API+"/deleteOrder",
            {
                method : "POST",
                body : JSON.stringify({
                    token : authCtx.token,
                    orderIdentifier : k?.orderIdentifier
                })
            });
            
            if(!deleteOrderResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const deleteOrderRespo = await deleteOrderResponse.json()
                if(deleteOrderRespo.status==="success") {
                    toast(`Successfully deleted the order!`)
                    getBulkOrders()
                } else {
                    if(deleteOrderRespo.message==="token Expired, please login again!") {
                        authCtx.logout()
                    } else {
                        
                    }
                }
                console.log(deleteOrderRespo)
            }
        } 
        console.log(k)
    }




    return (
        <div className={styles.mainWrapper}>
            <div onClick={() => rowSelectionHandler(item)} className={styles.sn} >
                {selectedItems.some((itemThis) => itemThis?.orderIdentifier === item?.orderIdentifier) ? (
                    <MdOutlineCheckBox size={24} />
                ) : (
                    <MdOutlineCheckBoxOutlineBlank size={24} />
                )}
            </div>
            <div className={styles.orderId}>
                <Link to={`/marketplace/bulk-order/${item?.orderIdentifier}`}>
                    {item?.orderIdentifier}
                </Link>
            </div>
            <div className={styles.date}>
                {item?.placedOn?.substring(0, 10)}
            </div>
            <div className={styles.date}>
                {item?.placedOn?.substring(11, 19)}
            </div>
            <div className={styles.item}>
                {item?.itemTitle}
            </div>
            <div className={styles.pointsUsed}>
                {item?.pointsUsed}
            </div>
            <div className={styles.phone}>
                {item?.receiverPhone}
            </div>
            <div className={styles.address}>
                {item?.deliveryAddress}
            </div>
            <div className={styles.pincode}>
                {item?.deliveryPincode}
            </div>
            <div className={styles.currentStatus}>
                {item?.currentStatus}
            </div>
            <div className={styles.action}>
                <button onClick={() => deleteOrderHandler(item)}>
                    <MdDelete />
                </button>
            </div>
        </div>
    )
}

export default EachRowViewBulkOrder