import React, { useContext, useEffect, useState } from 'react';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { Link } from 'react-router-dom';
import MainHeader from '../../Components/Headers/MainHeader';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';
import styles from './LaunchedPDS.module.css';

const LaunchedPDS = () => {
    const authCtx = useContext(AuthContext);
    const [allLaunched, setAllLaunched] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [loading, setLoading] = useState(false);
    const [clients, setClients] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);

    const getAllLaunchedPDS = async () => {
        setLoading(true);
        const response = await fetch(BASE_URL_API + '/getAllLaunchedPDS', {
            method: 'POST',
            body: JSON.stringify({ token: authCtx.token }),
        });

        if (!response.ok) {
            console.error('Server Error');
        } else {
            const data = await response.json();
            if (data.status === 'success') {
                setAllLaunched(data.response);
                setFilteredData(data.response);
                setClients([...new Set(data.response.map((item) => item.clientIdentifier))]);
            } else {
                if (data.message === 'token Expired, please login again!') {
                    authCtx.logout();
                } else {
                    setAllLaunched([]);
                    setFilteredData([]);
                }
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        getAllLaunchedPDS();
    }, []);

    const handleRowSelection = (id) => {
        if (selectedRows.includes(id)) {
            setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
        } else {
            setSelectedRows([...selectedRows, id]);
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        const searchValue = e.target.value.toLowerCase();

        const filtered = allLaunched.filter((item) =>
            item.pdsTitle.toLowerCase().includes(searchValue) ||
            item.brandName.toLowerCase().includes(searchValue) ||
            item.createdOn.toLowerCase().includes(searchValue) ||
            item.clientIdentifier.toLowerCase().includes(searchValue)
        );

        setFilteredData(filtered);
    };


    const handleClientFilter = (e) => {
        const value = e.target.value;
        setSelectedClients(value ? [value] : []);
        const filtered = value
            ? allLaunched.filter((item) => item.clientIdentifier === value)
            : allLaunched;
        setFilteredData(filtered);
    };

    const currentPageData = filteredData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    return (
        <div>
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.mainHeader}>
                    <div className={styles.pageTitle}>
                        All Launched PDS
                    </div>

                    <div className={styles.filters}>
                        <div className={styles.filterItem}>
                            <select
                                className={styles.filterSelect}
                                onChange={handleClientFilter}
                                value={selectedClients[0] || ''}
                            >
                                <option value="">All Clients</option>
                                {clients.map((client) => (
                                    <option key={client} value={client}>
                                        {client}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.filterItem}>
                            <input
                                type="text"
                                className={styles.filterInput}
                                placeholder="Search for anything..."
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                        </div>
                    </div>
                </div>

                {loading ? (
                    <div>Loading...</div>
                ) : (
                    <>
                        <div className={styles.headerWrapper}>
                            <div className={styles.checkBox}>
                                <MdCheckBoxOutlineBlank size={22} />
                            </div>
                            <div className={styles.title} >Title</div>
                            <div className={styles.brandName}>Client Name</div>
                            <div className={styles.createdOn} >Created On</div>
                            <div className={styles.action} >Action</div>
                        </div>
                        <div className={styles.rowsWrapper}>
                            {currentPageData.map((item) => (
                                <div
                                    key={item.ID}
                                    className={styles.eachRow}
                                    onClick={() => handleRowSelection(item.ID)}
                                >
                                    <div className={styles.checkBox}>
                                        {selectedRows.includes(item.ID) ? (
                                            <MdCheckBox size={22} />
                                        ) : (
                                            <MdCheckBoxOutlineBlank size={22} />
                                        )}
                                    </div>
                                    <div className={styles.title} >{item.pdsTitle}</div>
                                    <div className={styles.brandName}>{item.brandName}</div>
                                    <div className={styles.createdOn} >{item.createdOn}</div>
                                    <div className={styles.action} >
                                        <Link to={`/pds/${item?.pdsCampaignIdentifier}`}>View</Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className={styles.paginationContainer}>
                            <div>
                                <button
                                    className={styles.paginationButton}
                                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                    disabled={currentPage === 1}
                                >
                                    Previous
                                </button>
                                <button
                                    className={styles.paginationButton}
                                    onClick={() =>
                                        setCurrentPage((prev) =>
                                            Math.min(prev + 1, Math.ceil(filteredData.length / rowsPerPage))
                                        )
                                    }
                                    disabled={currentPage === Math.ceil(filteredData.length / rowsPerPage)}
                                >
                                    Next
                                </button>
                            </div>
                            <div>
                                <select
                                    className={styles.rowsPerPageSelect}
                                    value={rowsPerPage}
                                    onChange={(e) => setRowsPerPage(Number(e.target.value))}
                                >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="150">150</option>
                                    <option value="200">200</option>
                                    <option value="250">250</option>
                                </select>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default LaunchedPDS;
