import React from 'react'
import MainHeader from '../../Components/Headers/MainHeader'
import SettingsBreadcrumb from '../../Components/Settings/SettingsBreadcrumb'
import styles from './SettingsAPIsCDNs.module.css'

const SettingsAPIsCDNs = () => {
    return (
        <div>
            <MainHeader />
            <SettingsBreadcrumb />
            SettingsAPIsCDNs
            </div>
    )
}

export default SettingsAPIsCDNs