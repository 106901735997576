import React, { useState, useEffect } from 'react';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { toast } from 'react-toastify';
import styles from './OpBatchShippingForwardActions.module.css';

const OpBatchShippingForwardActions = (props) => {
    const { isOpen, close, itemsToForward = [] } = props;

    const [searchQuery, setSearchQuery] = useState('');
    const [sortColumn, setSortColumn] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [filters, setFilters] = useState({});
    const [filteredData, setFilteredData] = useState(itemsToForward);
    const [visibleRows, setVisibleRows] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRows, setSelectedRows] = useState([]);

    // Create unique filters on load
    useEffect(() => {
        const uniqueFilters = {
            logisticPartner: [
                ...new Set(itemsToForward.map(item => item.logisticPartner))
            ],
            warehouseName: [
                ...new Set(itemsToForward.map(item => item.warehouseName))
            ],
            productTitle: [
                ...new Set(itemsToForward.map(item => item.productTitle))
            ]
        };
        setFilters(uniqueFilters);
        setFilteredData(itemsToForward);
    }, [itemsToForward]);

    // Handle search
    useEffect(() => {
        const lowercasedQuery = searchQuery.toLowerCase();
        const data = itemsToForward.filter(item =>
            Object.values(item).some(value =>
                String(value).toLowerCase().includes(lowercasedQuery)
            )
        );
        setFilteredData(data);
    }, [searchQuery, itemsToForward]);

    // Handle sort
    const handleSort = (column) => {
        const direction = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
        const sortedData = [...filteredData].sort((a, b) => {
            if (a[column] < b[column]) return direction === 'asc' ? -1 : 1;
            if (a[column] > b[column]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
        setSortColumn(column);
        setSortDirection(direction);
        setFilteredData(sortedData);
    };

    // Handle pagination
    const handleChangePage = (page) => setCurrentPage(page);

    const paginatedData = filteredData.slice(
        (currentPage - 1) * visibleRows,
        currentPage * visibleRows
    );

    // Handle row selection
    const toggleRowSelection = (id) => {
        setSelectedRows((prev) =>
            prev.includes(id) ? prev.filter(row => row !== id) : [...prev, id]
        );
    };

    const toggleSelectAll = () => {
        if (selectedRows.length === filteredData.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(filteredData.map(item => item?.productIdentifier));
        }
    };

    const bookBulkOrder = async () => {
        if(window.confirm(`Are you sure you want to book order for shipment?`)) {
            toast(`Order Booked successfully!`)
        }
    }

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer} onClick={() => close()}>
                <div className={styles.mainContent} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.headerWrapper}>
                        <div>Book Courier Order(s)</div>
                        <button disabled={selectedRows?.length===0 && true} className={styles.btnBook} onClick={() => bookBulkOrder()} >Book {selectedRows?.length} Order(s)</button>
                    </div>
                    <div className={styles.bodyWrapper}>
                        {/* Search and Filters */}
                        <div className={styles.controls}>
                            <input
                                type="text"
                                placeholder="Search for anything..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <div className={styles.filters}>
                                {Object.keys(filters).map((key) => (
                                    <select
                                        key={key}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const data = itemsToForward.filter(item =>
                                                item[key] === value || value === ''
                                            );
                                            setFilteredData(data);
                                        }}
                                    >
                                        <option value="">All {key}</option>
                                        {filters[key].map((filterValue, index) => (
                                            <option key={index} value={filterValue}>
                                                {filterValue}
                                            </option>
                                        ))}
                                    </select>
                                ))}
                            </div>
                        </div>

                        {/* Table Header */}
                        <div className={styles.shipmentTableHeader}>
                            <div className={styles.selector} onClick={toggleSelectAll}>
                                {selectedRows.length === filteredData.length ? (
                                    <MdCheckBox size={20} />
                                ) : (
                                    <MdCheckBoxOutlineBlank size={20} />
                                )}
                            </div>
                            <div
                                className={styles.productTitle}
                                onClick={() => handleSort('productTitle')}
                            >
                                Product Title {sortColumn === 'productTitle' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                            </div>
                            <div
                                className={styles.warehouseName}
                                onClick={() => handleSort('warehouseName')}
                            >
                                Pick-up From {sortColumn === 'warehouseName' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                            </div>
                            <div
                                className={styles.logisticPartner}
                                onClick={() => handleSort('logisticPartner')}
                            >
                                Courier {sortColumn === 'logisticPartner' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
                            </div>
                            <div className={styles.action}>Action</div>
                        </div>

                        {/* Table Rows */}
                        <div>
                            {paginatedData.map((item, index) => (
                                <div key={index} className={styles.eachShipmentWrapper}>
                                    <div
                                        className={styles.selector}
                                        onClick={() => toggleRowSelection(item.productIdentifier)}
                                    >
                                        {selectedRows.includes(item.productIdentifier) ? (
                                            <MdCheckBox size={20} />
                                        ) : (
                                            <MdCheckBoxOutlineBlank size={20} />
                                        )}
                                    </div>
                                    <div className={styles.productTitle}>{item.productTitle}</div>
                                    <div className={styles.warehouseName}>{item.warehouseName}</div>
                                    <div className={styles.logisticPartner}>{item.logisticPartner}</div>
                                    <div className={styles.action}>
                                        <button>Book Now</button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Pagination */}
                    <div className={styles.pagination}>
                        {Array.from(
                            { length: Math.ceil(filteredData.length / visibleRows) },
                            (_, idx) => (
                                <button
                                    key={idx}
                                    onClick={() => handleChangePage(idx + 1)}
                                    className={currentPage === idx + 1 ? styles.active : ''}
                                >
                                    {idx + 1}
                                </button>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OpBatchShippingForwardActions;
