import React from 'react'
import { FaShippingFast } from 'react-icons/fa'
import { MdOutlineInsertComment } from 'react-icons/md'
import MainHeader from '../../Components/Headers/MainHeader'
import SettingsBreadcrumb from '../../Components/Settings/SettingsBreadcrumb'
import styles from './SettingsAPIsCommunications.module.css'

const SettingsAPIsCommunications = () => {
    return (
        <div>
            <MainHeader />
            <SettingsBreadcrumb />
            <div className={styles.sectionBody}>
                <div className={styles.eachSubSectionWrapper}>
                    <div className={styles.subSectionTitle} ><MdOutlineInsertComment /> Communication</div>
                    <div className={styles.optionsWrapper}>
                        <div className={styles.eachOptionWrapper} >
                            <div>SMS</div>
                            <div>
                                <form>
                                    <input placeholder="URL" />
                                    <input placeholder="Token" />
                                    <input placeholder="Username" />
                                    <input placeholder="Password" />
                                </form>
                            </div>
                        </div>

                        <div className={styles.eachOptionWrapper} >
                            <div>WhatsApp</div>
                            <div>
                                <input placeholder="URL" />
                                <input placeholder="Token" />
                                <input placeholder="Phone" />
                            </div>
                        </div>

                    </div>
                </div>


            </div>
        </div>
    )
}

export default SettingsAPIsCommunications