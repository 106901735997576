import React, { useContext, useEffect, useState } from 'react'
import { MdDeleteForever, MdEdit, MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank, MdViewList } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import MainHeader from '../../Components/Headers/MainHeader'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './AllVendors.module.css'

const AllVendors = () => {
    const authCtx = useContext(AuthContext)
    const [allVendors, setAllVendors] = useState([])
    const [selectedVendors, setSelectedVendors] = useState([])

    const getAllVendors = async () => {
        const getAllVendorsResponse = await fetch(BASE_URL_API + "/getAllVendors",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllVendorsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllVendorsRespo = await getAllVendorsResponse.json()
            if (getAllVendorsRespo.status === "success") {
                setAllVendors(getAllVendorsRespo?.response)
            } else {
                if (getAllVendorsRespo?.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getAllVendorsRespo)
        }
    }

    useEffect(() => {
        getAllVendors()
    }, [])

    const selectVendorHandler = k => {
        if (selectedVendors?.some(item => item?.vendorIdentifier === k.vendorIdentifier)) {
            setSelectedVendors(
                selectedVendors?.filter((itemToFilter => itemToFilter?.vendorIdentifier !== k?.vendorIdentifier))
            )
        } else {
            setSelectedVendors([...selectedVendors, k])
        }
    }

    // Add the function to select or deselect all vendors
    const selectAllHandler = () => {
        if (selectedVendors.length === allVendors.length) {
            setSelectedVendors([]); // Deselect all if all are already selected
        } else {
            setSelectedVendors(allVendors); // Select all vendors
        }
    };


    const deleteVendorHandler = async (k) => {
        if (window.confirm(`Do you really want to delete the vendor ${k?.companyName}`)) {
            const deleteVendorResponse = await fetch(BASE_URL_API + "/deleteVendor",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        vendorIdentifier: k?.vendorIdentifier
                    })
                });

            if (!deleteVendorResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const deleteVendorRespo = await deleteVendorResponse.json()
                if (deleteVendorRespo.status === "success") {
                    toast(`Successfully deleted the vendor`)
                    getAllVendors()
                } else {
                    if (deleteVendorRespo.message === "token Expired, please login again!") {
                        authCtx.logout()
                    } else {

                    }
                    console.log(deleteVendorRespo)
                }
            }
        }
    }

    return (
        <div>
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.pageHeader} >
                    <div>
                        All Vendors
                    </div>
                    <div className={styles.actionWrapper}>
                        <div>Import</div>
                        <div>Filters</div>
                        <Link to={`/vendors/onboard`} className={styles.addNewBtn} >Add New</Link>
                    </div>
                </div>
                <div>
                    <div className={styles.tableHeader}>
                        <div className={styles.checkBox} onClick={selectAllHandler}>
                            {selectedVendors?.length === allVendors?.length ?
                                <MdOutlineCheckBox size={22} />
                                :
                                <MdOutlineCheckBoxOutlineBlank size={22} />
                            }
                        </div>

                        <div className={styles.companyName}>
                            Company Name
                        </div>
                        <div className={styles.gstin}>
                            GSTIN
                        </div>
                        <div className={styles.contactNo}>Phone</div>
                        <div className={styles.billingCity}>City</div>
                        <div className={styles.billingState}>State</div>
                        <div className={styles.actions}>Actions</div>
                    </div>
                    <div className={styles.tableBody}>
                        {allVendors?.map((item, index) => (
                            <div className={styles.eachVendorWrapper} key={index}>
                                <div className={styles.checkBox} onClick={() => selectVendorHandler(item)} >
                                    {selectedVendors?.some(itemToFind => itemToFind?.vendorIdentifier === item?.vendorIdentifier) === true ?
                                        <MdOutlineCheckBox size={24} />
                                        :
                                        <MdOutlineCheckBoxOutlineBlank size={24} />
                                    }
                                </div>
                                <div className={styles.companyName} >{item?.companyName}</div>
                                <div className={styles.gstin}>{item?.gstin}</div>
                                <div className={styles.contactNo}>{item?.contactNo}</div>
                                <div className={styles.billingCity}>{item?.billingCity}</div>
                                <div className={styles.billingState}>{item?.billingState}</div>
                                <div className={styles.actions}>
                                    <div><MdEdit size={18} /> Edit</div>
                                    <div><Link to={`/vendor/${item?.vendorIdentifier}`}> <MdViewList size={18} /> View</Link> </div>
                                    <div className={styles.delete} onClick={() => deleteVendorHandler(item)} ><MdDeleteForever size={18} /> Delete</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllVendors