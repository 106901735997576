import React, { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import styles from './CreateNewInvoice.module.css';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';


const CreateNewInvoice = (props) => {
    const { isOpen, onClose } = props;
    const authCtx = useContext(AuthContext)
    const [invoiceType, setInvoiceType] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleCreateInvoice = async () => {
        if (!invoiceType) {
            alert('Please select an invoice type.');
            return;
        }

        setIsSubmitting(true);
        try {
            const response = await fetch(`${BASE_URL_API}/createNewInvoice`, 
            {
                method : "POST",
                body : JSON.stringify({
                    type: invoiceType,
                    token : authCtx?.token
                })
            })
            const respo = await response.json()
            if(respo?.status==="success") {
                const invoiceIdentifier =respo?.response;
                navigate(`/invoice/creating/${invoiceIdentifier}`);
            } else {
                if(respo.message==="token Expired, please login again!") {
                    authCtx.logout()
                }
            }

        } catch (error) {
            console.error('Error creating invoice:', error);
            alert('Failed to create invoice. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <motion.div
            className={styles.overlay}
            onClick={handleOverlayClick}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <motion.div
                className={styles.modal}
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
                transition={{ duration: 0.3 }}
            >
                <button className={styles.closeButton} onClick={onClose}>
                    &times;
                </button>
                <h2 className={styles.title}>Create New Invoice</h2>
                <div className={styles.optionsContainer}>
                    <button
                        className={`${styles.optionButton} ${invoiceType === 'Sale' ? styles.selected : ''}`}
                        onClick={() => setInvoiceType('Sale')}
                    >
                        Sale Invoice
                    </button>
                    <button
                        className={`${styles.optionButton} ${invoiceType === 'Purchase' ? styles.selected : ''}`}
                        onClick={() => setInvoiceType('Purchase')}
                    >
                        Purchase Invoice
                    </button>
                </div>
                <button
                    className={styles.createButton}
                    onClick={handleCreateInvoice}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Creating...' : 'Create'}
                </button>
            </motion.div>
        </motion.div>
    );
};

export default CreateNewInvoice;
