import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';
import styles from './LaunchPDS.module.css';

const LaunchPDS = (props) => {
    const { isOpen, onClose, data } = props;
    const [client, setClient] = useState('');
    const [isLaunching, setIsLaunching] = useState(false)
    const authCtx = useContext(AuthContext)
    const [allClients, setAllClients] = useState([])
    const nav = useNavigate()

    const getAllClients = async () => {
        const getAllClientsResponse = await fetch(BASE_URL_API+"/getAllClients",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token
            })
        });

        if(!getAllClientsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllClientsRespo = await getAllClientsResponse.json()
            if(getAllClientsRespo.status==="success") {
                setAllClients(getAllClientsRespo?.response)
            } else {
                if(getAllClientsRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllClients([])
                }
            }
            console.log(getAllClientsRespo)
        }
    }


    const handleSubmit = async (e) => {
        setIsLaunching(true)
        e.preventDefault(); // Prevent default form submission behavior
        const createNewPdsForClientResponse = await fetch(BASE_URL_API + "/createNewPdsForClient",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    clientData: client,
                    pdsData: data
                })
            });

        if (!createNewPdsForClientResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const createNewPdsForClientRespo = await createNewPdsForClientResponse.json()
            if (createNewPdsForClientRespo?.status === "success") {
                nav(createNewPdsForClientRespo?.response?.pdsCampaignIdentifier)
            } else {
                if (createNewPdsForClientRespo?.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(createNewPdsForClientRespo)
        }


        setIsLaunching(false)
    };

    useEffect(() => {
        setIsLaunching(false)
    }, [client])

    useEffect(() => {
        if(isOpen) {
            getAllClients()
        }
    }, [isOpen])
    
    

    if (!isOpen) {
        return null;
    }

    return (
        <div className={styles.mainWrapper}>
            <div
                className={styles.mainContainer}
                onClick={onClose}
            >
                <div
                    className={styles.mainContent}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className={styles.title} >Launch {data?.title}</div>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.selectWrapper} >
                            <select value={client} onChange={(e) => setClient(e.target.value)}>
                                <option value="">Select A Client</option>
                                {allClients?.map((item, index) => (
                                    <option key={index} value={JSON.stringify(item)}>{item?.brandName}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            {isLaunching === true ?
                                <input type="submit" disabled value="Launching..." />
                                :
                                <input type="submit" value="Launch Now" />
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default LaunchPDS;
