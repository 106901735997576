import React, { useEffect, useState } from 'react';
import { MdSettings } from 'react-icons/md';
import { Link } from 'react-router-dom';
import styles from './SettingsBreadcrumb.module.css';

const SettingsBreadcrumb = () => {
    const [breadcrumbArr, setBreadcrumbArr] = useState([]);

    useEffect(() => {
        const paths = window.location.pathname;
        const allPaths = paths.split('/').filter(Boolean); // Filter out empty strings
        setBreadcrumbArr(allPaths);
    }, []);

    const generatePath = (index) => {
        return '/' + breadcrumbArr.slice(0, index + 1).join('/');
    };

    return (
        <div className={styles.pathsWrapper}>
            {breadcrumbArr.map((item, index) => (
                <Link
                    to={generatePath(index)}
                    className={`${styles.eachPath} ${index === breadcrumbArr.length - 1 ? styles.active : ''
                        }`}
                    key={index}
                >
                    <MdSettings size={20} />
                    <span>{item.replace(/-/g, ' ').toUpperCase()}</span>
                </Link>
            ))}
        </div>
    );
};

export default SettingsBreadcrumb;
