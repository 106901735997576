import React, { useContext, useEffect, useState } from 'react';
import { MdCheckBox, MdCheckBoxOutlineBlank, MdFilterAlt } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';
import styles from './ELPDSProducts.module.css';

const ELPDSProducts = (props) => {
    const { clientIdentifier } = props;
    const authCtx = useContext(AuthContext);

    const [selectedUser, setSelectedUser] = useState([]);
    const [productData, setProductData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [isAllocationOpen, setIsAllocationOpen] = useState(false);
    const [bulkPointsPercentage, setBulkPointsPercentage] = useState(0);

    const closeAllocation = () => setIsAllocationOpen(false);

    const getAllClientProducts = async () => {
        const response = await fetch(BASE_URL_API + '/getAllClientProducts', {
            method: 'POST',
            body: JSON.stringify({
                token: authCtx.token,
                clientIdentifier: clientIdentifier,
            }),
        });

        if (!response.ok) {
            console.error('Something went wrong: Server Error');
        } else {
            const result = await response.json();
            if (result.status === 'success') {
                setProductData(result.response);
            } else {
                if (result.message === 'token Expired, please login again!') {
                    authCtx.logout();
                } else {
                    setProductData([]);
                }
            }
        }
    };

    useEffect(() => {
        if (clientIdentifier) {
            getAllClientProducts();
        }
    }, [clientIdentifier]);

    const toggleSelectUser = (userIdentifier) => {
        setSelectedUser((prev) => {
            if (prev.includes(userIdentifier)) {
                return prev.filter((id) => id !== userIdentifier);
            }
            return [...prev, userIdentifier];
        });
    };

    const toggleSelectAll = () => {
        if (selectedUser.length === filteredData.length) {
            setSelectedUser([]);
        } else {
            setSelectedUser(filteredData.map((user) => user.productIdentifier));
        }
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const filteredData = productData?.filter((item) => {
        const allString = `${item?.brandName} ${item.productDescription || ''}`?.toLowerCase();
        return (
            item?.productIdentifier?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
            allString?.includes(searchQuery.toLowerCase()) ||
            item.productTitle.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    const updateProductPoints = (productIdentifier, points) => {
        setProductData((prev) =>
            prev.map((item) =>
                item.productIdentifier === productIdentifier
                    ? { ...item, pointsOnSale: points }
                    : item
            )
        );
    };

    const pointsAllocationHandler = async () => {
        console.log('Updated Product Data:', productData);
        const updatePointsOnSaleToClientResponse = await fetch(BASE_URL_API+"/updatePointsOnSaleToClient",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                productsData : productData
            })
        });

        if(!updatePointsOnSaleToClientResponse.ok) {
            console.log("Something went wrong : Server error")
        } else {
            const updatePointsOnSaleToClientRespo = await updatePointsOnSaleToClientResponse.json()
            if(updatePointsOnSaleToClientRespo.status==="success") {

            } else {
                if(updatePointsOnSaleToClientRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(updatePointsOnSaleToClientRespo)
        }
    };

    const applyBulkPoints = () => {
        setProductData((prev) =>
            prev.map((item) => {
                if (selectedUser.includes(item.productIdentifier)) {
                    const newPoints = Math.round(item.mrp * (bulkPointsPercentage / 100));
                    return { ...item, pointsOnSale: newPoints };
                }
                return item;
            })
        );
        closeAllocation();
    };

    return (
        <div>
            <div className={styles.header}>
                <div></div>
                <div className={styles.actionBtnWrapper}>
                    <button onClick={() => setIsAllocationOpen(true)}>Allocate</button>
                    <button onClick={() => pointsAllocationHandler()}>Save</button>
                </div>
            </div>
            <div className={styles.filterWrapper}>
                <div className={styles.searchContainer}>
                    <input
                        type="text"
                        placeholder="Search by User ID, Name, or City..."
                        value={searchQuery}
                        onChange={handleSearch}
                        className={styles.searchInput}
                    />
                </div>
                <div className={styles.fwRightWrapper}>
                    <div>
                        <MdFilterAlt />
                    </div>
                    <div className={styles.addNewProduct}>
                        <Link to={`/clients/${clientIdentifier}/products`}>Add New Products</Link>
                    </div>
                </div>
            </div>
            <div className={styles.tableHeader}>
                <div onClick={toggleSelectAll} className={styles.checkboxContainer}>
                    {selectedUser.length === filteredData.length && filteredData.length > 0 ? (
                        <MdCheckBox size={24} />
                    ) : (
                        <MdCheckBoxOutlineBlank size={24} />
                    )}
                </div>
                <div className={styles.userIdentifier}>Product ID</div>
                <div className={styles.productTitle} >Product Title</div>
                <div className={styles.modelNo} >Model No.</div>
                <div className={styles.productDescription}>Product Description</div>
                <div className={styles.mrp} >MRP</div>
                <div className={styles.points}>Points Offered</div>
            </div>
            <div className={styles.tableRowsWrapper}>
                {currentRows.map((item, index) => (
                    <div
                        key={index}
                        className={
                            selectedUser.includes(item?.productIdentifier)
                                ? styles.selectedRow
                                : styles.eachUserRow
                        }
                        onClick={() => toggleSelectUser(item?.productIdentifier)}
                    >
                        <div className={styles.checkboxContainer}>
                            {selectedUser.includes(item?.productIdentifier) ? (
                                <MdCheckBox size={20} />
                            ) : (
                                <MdCheckBoxOutlineBlank size={20} />
                            )}
                        </div>
                        <div className={styles.userIdentifier}>{item?.productIdentifier}</div>
                        <div className={styles.productTitle} >{item?.productTitle}</div>
                        <div className={styles.modelNo} >{item?.modelNo}</div>
                        <div className={styles.productDescription}>{item?.productDescription}</div>
                        <div className={styles.mrp} >{item?.mrp}</div>
                        <div className={styles.points}>
                            <input
                                type="text"
                                value={item?.pointsOnSale}
                                onChange={(e) =>
                                    updateProductPoints(item.productIdentifier, e.target.value)
                                }
                                onFocus={(e) => e.target.select()}
                                onBlur={(e) => updateProductPoints(item.productIdentifier, e.target.value)}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.tableFooter}>
                <div className={styles.paginationContainer}>
                    <label>Rows per page: </label>
                    <select
                        value={rowsPerPage}
                        onChange={(e) => setRowsPerPage(Number(e.target.value))}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </div>
                <div className={styles.paginationControls}>
                    <button
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                    <button
                        onClick={() =>
                            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                        }
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </div>
            {isAllocationOpen && (
                <div className={styles.modal}>
                    <div className={styles.modalContainer}>
                        <div className={styles.modalContent}>
                            <div className={styles.modalHeader}>Allocate Points in Bulk</div>
                            <div>
                                <div className={styles.modalInput}>
                                    <input
                                        type="number"
                                        value={bulkPointsPercentage}
                                        onChange={(e) => setBulkPointsPercentage(Number(e.target.value))}
                                        placeholder="Enter percentage of MRP"
                                    />
                                </div>
                                <div className={styles.modalAction}>
                                    <button onClick={applyBulkPoints}>Apply</button>
                                    <button onClick={closeAllocation}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ELPDSProducts;
