import React, { useState, useEffect } from "react";
import SearchableDropdown from "../../Components/Invoicing/SearchableDropdown";
import styles from "./ProductSearch.module.css";


const ProductSearch = ({ allRecords, onSelectRecords }) => {
    const [filteredData, setFilteredData] = useState([])

    useEffect(() => {
        setFilteredData(allRecords);
    }, [allRecords]);

    return (
        <div className={styles.panelHeader}>
            <SearchableDropdown
                placeholder="Search product by name"
                data={filteredData}
                filterKey="productTitle"
                onItemSelect={onSelectRecords}
            />
        </div>
    );
};

export default ProductSearch;
