import React, { useContext, useEffect, useState } from 'react';
import MainHeader from '../../Components/Headers/MainHeader';
import EachRowViewBulkOrder from '../../Components/Marketplace/EachRowViewBulkOrder';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';
import styles from './BulkOrders.module.css';

import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

const BulkOrders = () => {
    const authCtx = useContext(AuthContext);
    const nav = useNavigate();

    const [ordersData, setOrdersData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    // Fetch orders
    const getBulkOrders = async () => {
        const response = await fetch(BASE_URL_API + '/getBulkOrders', {
            method: 'POST',
            body: JSON.stringify({ token: authCtx.token }),
        });
        if (!response.ok) {
            console.log('Something went wrong: Server Error');
        } else {
            const data = await response.json();
            if (data.status === 'success') {
                setOrdersData(data.response);
                setFilteredData(data.response);
            } else {
                if (data.message === 'token Expired, please login again!') {
                    authCtx.logout();
                } else {
                    setOrdersData([]);
                }
            }
        }
    };

    useEffect(() => {
        getBulkOrders();
    }, []);

    // Search functionality
    const handleSearch = (query) => {
        setSearchQuery(query);
        const filtered = ordersData.filter((item) =>
            Object.values(item).some((val) =>
                String(val).toLowerCase().includes(query.toLowerCase())
            )
        );
        setFilteredData(filtered);
        setCurrentPage(1); // Reset to first page
    };

    // Sorting functionality
    const handleSort = (key) => {
        const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
        const sortedData = [...filteredData].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
            return 0;
        });
        setFilteredData(sortedData);
        setSortConfig({ key, direction });
    };

    // Pagination
    const paginatedData = filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    const nextPage = () => {
        if (currentPage * rowsPerPage < filteredData.length) setCurrentPage(currentPage + 1);
    };

    const prevPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const handleRowsPerPageChange = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setCurrentPage(1); // Reset to first page
    };

    // Row selection
    const rowSelectionHandler = (item) => {
        setSelectedData((prevSelectedData) =>
            prevSelectedData.some((i) => i.orderIdentifier === item.orderIdentifier)
                ? prevSelectedData.filter((i) => i.orderIdentifier !== item.orderIdentifier)
                : [...prevSelectedData, item]
        );
    };

    const handleAllSelector = () => {
        if (paginatedData.every((item) => selectedData.includes(item))) {
            setSelectedData(selectedData.filter((item) => !paginatedData.includes(item)));
        } else {
            setSelectedData([...selectedData, ...paginatedData.filter((item) => !selectedData.includes(item))]);
        }
    };

    return (
        <div>
            <MainHeader />
            <div className={styles.mainContainer}>
                <div className={styles.mainHeader}>
                    <div className={styles.title}>View All Bulk Orders</div>
                    <div className={styles.selectedTxt}>
                        {selectedData.length} / {filteredData.length} orders selected
                    </div>
                    <div className={styles.btn}>
                        {selectedData.length > 0 ? (
                            <button
                                onClick={() => alert(`Process order for ${selectedData.length} orders`)}
                                className={styles.initiateBtn}
                            >
                                Process Order
                            </button>
                        ) : (
                            <button
                                onClick={() => alert('Please select at least one order to process them!')}
                                className={styles.initiateBtn}
                            >
                                Process Order
                            </button>
                        )}
                        <Link to={`/marketplace/order-processing/batches`} className={styles.initiateBtn}>
                            View Processed Orders
                        </Link>
                    </div>
                </div>

                <div className={styles.tableWrapper}>
                    <div className={styles.searchAndFilter}>
                        <input
                            type="text"
                            placeholder="Search orders..."
                            value={searchQuery}
                            onChange={(e) => handleSearch(e.target.value)}
                            className={styles.searchInput}
                        />
                    </div>

                    <div className={styles.tableHeader}>
                        <div onClick={handleAllSelector} className={styles.sn}>
                            {paginatedData.every((item) => selectedData.includes(item)) ? (
                                <MdCheckBox size={24} />
                            ) : (
                                <MdCheckBoxOutlineBlank size={24} />
                            )}
                        </div>
                        <div className={styles.orderId} onClick={() => handleSort('orderIdentifier')}>
                            Order Id
                        </div>
                        <div className={styles.date} onClick={() => handleSort('date')}>
                            Date
                        </div>
                        <div className={styles.date} >
                            Time
                        </div>
                        <div className={styles.item} onClick={() => handleSort('productTitle')}>
                            Item
                        </div>
                        <div className={styles.pointsUsed} onClick={() => handleSort('pointsUsed')}>
                            Points Used
                        </div>
                        <div className={styles.phone}>Phone</div>
                        <div className={styles.address}>Address </div>
                        <div className={styles.pincode}>Pincode</div>
                        <div className={styles.currentStatus}>Current Status</div>
                        <div className={styles.action} >Action</div>
                    </div>

                    <div className={styles.tableBody}>
                        {paginatedData.map((item, index) => (
                            <EachRowViewBulkOrder
                                count={index + 1 + (currentPage - 1) * rowsPerPage}
                                item={item}
                                key={index}
                                selectedItems={selectedData}
                                rowSelectionHandler={rowSelectionHandler}
                            />
                        ))}
                    </div>

                    <div className={styles.pagination}>
                        <div>
                            Rows per page:
                            <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
                                {[5, 10, 20, 50].map((value) => (
                                    <option key={value} value={value}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.paginationBtn}>
                            <button onClick={prevPage} disabled={currentPage === 1}>
                                Previous
                            </button>
                            <span>
                                Page {currentPage} of {Math.ceil(filteredData.length / rowsPerPage)}
                            </span>
                            <button onClick={nextPage} disabled={currentPage * rowsPerPage >= filteredData.length}>
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BulkOrders;
