import React, { useContext, useEffect, useState } from 'react';
import { MdCheckBox, MdCheckBoxOutlineBlank, MdFilterAlt } from 'react-icons/md';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';
import ELPDSPointAllocation from './ELPDSPointAllocation';
import styles from './ELPDSUsers.module.css';

const ELPDSUsers = (props) => {
    const { clientIdentifier, pdsCampaignIdentifier } = props;
    const authCtx = useContext(AuthContext);

    const [selectedUser, setSelectedUser] = useState([]);
    const [userData, setUserData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [isAllocationOpen, setIsAllocationOpen] = useState(false)
    const closeAllocation = () => setIsAllocationOpen(false)

    const getAllClientsUser = async () => {
        const response = await fetch(BASE_URL_API + '/getAllClientsUser', {
            method: 'POST',
            body: JSON.stringify({
                token: authCtx.token,
                clientIdentifier: clientIdentifier,
            }),
        });

        if (!response.ok) {
            console.error('Something went wrong: Server Error');
        } else {
            const result = await response.json();
            if (result.status === 'success') {
                setUserData(result.response);
            } else {
                if (result.message === 'Token expired, please login again!') {
                    authCtx.logout();
                } else {
                    setUserData([]);
                }
            }
        }
    };

    useEffect(() => {
        if (clientIdentifier) {
            getAllClientsUser();
        }
    }, [clientIdentifier]);

    const toggleSelectUser = (userIdentifier) => {
        setSelectedUser((prev) => {
            if (prev.includes(userIdentifier)) {
                return prev.filter((id) => id !== userIdentifier);
            }
            return [...prev, userIdentifier];
        });
    };

    const toggleSelectAll = () => {
        if (selectedUser.length === filteredData.length) {
            setSelectedUser([]);
        } else {
            setSelectedUser(filteredData.map((user) => user.userIdentifier));
        }
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const filteredData = userData.filter((item) => {
        const fullName = `${item.firstName} ${item.middleName || ''} ${item.lastName}`.toLowerCase();
        return (
            item.userIdentifier.toLowerCase().includes(searchQuery.toLowerCase()) ||
            fullName.includes(searchQuery.toLowerCase()) ||
            item.city.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    return (
        <div>
            <ELPDSPointAllocation 
                selectedUser={selectedUser} isOpen={isAllocationOpen} 
                close={closeAllocation} clientIdentifier={clientIdentifier}  
                pdsCampaignIdentifier={pdsCampaignIdentifier}
            />
            <div className={styles.header}>
                <div>

                </div>
                <div className={styles.actionBtnWrapper}>
                    <button onClick={() => setIsAllocationOpen(true)} >Allocate</button>
                    <button>View</button>
                </div>
            </div>
            <div className={styles.filterWrapper}>
                <div className={styles.searchContainer}>
                    <input
                        type="text"
                        placeholder="Search by User ID, Name, or City..."
                        value={searchQuery}
                        onChange={handleSearch}
                        className={styles.searchInput}
                    />
                </div>
                <div>
                    <MdFilterAlt />
                </div>

            </div>


            <div className={styles.tableHeader}>
                <div onClick={toggleSelectAll} className={styles.checkboxContainer}>
                    {selectedUser.length === filteredData.length && filteredData.length > 0 ? (
                        <MdCheckBox size={24} />
                    ) : (
                        <MdCheckBoxOutlineBlank size={24} />
                    )}
                </div>
                <div className={styles.userIdentifier} >User ID</div>
                <div className={styles.fullName} >Full Name</div>
                <div className={styles.phoneNumber} >Phone</div>
                <div className={styles.workEmail}>Work Email</div>
                <div className={styles.city}>City</div>
            </div>
            <div className={styles.tableRowsWrapper}>
                {currentRows.map((item) => (
                    <div
                        key={item.userIdentifier}
                        className={
                            selectedUser.includes(item.userIdentifier)
                                ? styles.selectedRow
                                : styles.eachUserRow
                        }
                        onClick={() => toggleSelectUser(item.userIdentifier)}
                    >
                        <div
                            className={styles.checkboxContainer}
                        >
                            {selectedUser.includes(item.userIdentifier) ? (
                                <MdCheckBox size={20} />
                            ) : (
                                <MdCheckBoxOutlineBlank size={20} />
                            )}
                        </div>
                        <div className={styles.userIdentifier} >{item.userIdentifier}</div>
                        <div className={styles.fullName} >
                            {item.firstName} {item.middleName} {item.lastName}
                        </div>
                        <div className={styles.phoneNumber} >{item.phoneNumber}</div>
                        <div className={styles.workEmail}>{item.workEmail}</div>
                        <div className={styles.city}>{item.city}</div>
                    </div>
                ))}
            </div>
            <div className={styles.tableFooter}>
                <div className={styles.paginationContainer}>
                    <label>Rows per page: </label>
                    <select
                        value={rowsPerPage}
                        onChange={(e) => setRowsPerPage(Number(e.target.value))}
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </div>
                <div className={styles.paginationControls}>
                    <button
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    <span>
                        Page {currentPage} of {totalPages}
                    </span>
                    <button
                        onClick={() =>
                            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                        }
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </div>

        </div>
    );
};

export default ELPDSUsers;
