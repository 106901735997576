import React, { useState } from 'react';
import styles from './ELPDSConfig.module.css';
import { MdNotificationsActive, MdNotificationsOff } from 'react-icons/md';
import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import { BsFillGearFill } from 'react-icons/bs';

const ELPDSConfig = () => {
    const [notifyUsers, setNotifyUsers] = useState(true);
    const [emailNotifications, setEmailNotifications] = useState(false);
    const [smsNotifications, setSmsNotifications] = useState(false);
    const [campaignActive, setCampaignActive] = useState(true);

    return (
        <div className={styles.configWrapper}>
            <div className={styles.configContainer}>
                <div className={styles.headerWrapper}>
                    <div className={styles.header}>Configurations <BsFillGearFill className={styles.icon} /></div>
                    {/* Save Button */}
                    <div className={styles.buttonWrapper}>
                        <button
                            className={styles.primaryButton}
                            onClick={() => {
                                console.log({
                                    notifyUsers,
                                    emailNotifications,
                                    smsNotifications,
                                    campaignActive,
                                });
                                alert('Settings saved successfully!');
                            }}
                        >
                            Save Configurations
                        </button>
                    </div>
                </div>



                <div className={styles.settingsWrapper}>
                    {/* Notify Each User */}
                    <div className={styles.configItem}
                        onClick={() => setNotifyUsers(!notifyUsers)}
                    >
                        <div className={styles.label}>Notify each user on points allocation!</div>
                        <div
                            className={styles.toggle}
                        >
                            {notifyUsers ? (
                                <MdNotificationsActive className={styles.activeIcon} />
                            ) : (
                                <MdNotificationsOff className={styles.inactiveIcon} />
                            )}
                        </div>
                    </div>

                    {/* Email Notifications */}
                    <div className={styles.configItem}
                        onClick={() => setEmailNotifications(!emailNotifications)}
                    >
                        <div className={styles.label}>Enable Email Notifications</div>
                        <div
                            className={styles.toggle}
                        >
                            {emailNotifications ? (
                                <AiFillCheckCircle className={styles.activeIcon} />
                            ) : (
                                <AiOutlineCheckCircle className={styles.inactiveIcon} />
                            )}
                        </div>
                    </div>

                    {/* SMS Notifications */}
                    <div className={styles.configItem}
                        onClick={() => setSmsNotifications(!smsNotifications)}
                    >
                        <div className={styles.label}>Enable SMS Notifications</div>
                        <div
                            className={styles.toggle}
                        >
                            {smsNotifications ? (
                                <AiFillCheckCircle className={styles.activeIcon} />
                            ) : (
                                <AiOutlineCheckCircle className={styles.inactiveIcon} />
                            )}
                        </div>
                    </div>

                    {/* Campaign Active */}
                    <div className={styles.configItem}
                        onClick={() => setCampaignActive(!campaignActive)}
                    >
                        <div className={styles.label}>Activate Campaign</div>
                        <div
                            className={styles.toggle}
                        >
                            {campaignActive ? (
                                <AiFillCheckCircle className={styles.activeIcon} />
                            ) : (
                                <AiOutlineCheckCircle className={styles.inactiveIcon} />
                            )}
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default ELPDSConfig;