import React from 'react'
import { Link } from 'react-router-dom'
import styles from './EachClientRow.module.css'
import { MdOutlineManageAccounts, MdPriceCheck } from "react-icons/md";
import { FaList } from 'react-icons/fa';


const EachClientRow = (props) => {
    const { count, item } = props
    console.log(item)

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.sn}>
                {count + 1}.
            </div>
            <div className={styles.brandName}>
                {item?.brandName}
            </div>
            <div className={styles.firmNameWrapper}>
                <div className={styles.firmName}>
                    {item?.firmName}
                </div>
                <div className={styles.officeAddress}>
                    {item?.officeAddress}
                </div>
            </div>
            <div className={styles.gst}>
                {item?.gstNumber}
            </div>
            <div className={styles.cin}>
                {item?.cin}
            </div>
            <div className={styles.createdOn}>
                {item?.createdOn?.substring(0, 10)}
            </div>
            <div className={styles.manageAuth}>
                <Link to={`/clients/${item?.clientIdentifier}/products`} className={styles.eachBtn} >
                    <FaList size={16} /> Products
                </Link>
                <Link to={`/clients/${item?.clientIdentifier}/auth`} className={styles.eachBtn} >
                    <MdOutlineManageAccounts size={16} />  Manage Auth
                </Link>
            </div>
        </div>
    )
}

export default EachClientRow