import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import MainHeader from '../../Components/Headers/MainHeader'
import SettingsBreadcrumb from '../../Components/Settings/SettingsBreadcrumb'
import { humanReadableTimePassed } from '../../Helpers/calculations'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './SettingsAPIsLogisticsEach.module.css'

const SettingsAPIsLogisticsEach = () => {
    const { credentialsIdentifier } = useParams()
    const authCtx = useContext(AuthContext)
    const [credentialsData, setCredentialsData] = useState(null)
    const [isGenerating, setIsGenerating] = useState(false)

    const eachLogisticAPICreds = async () => {
        const eachLogisticAPICredsResponse = await fetch(BASE_URL_API + "/getEachLogisticAPICreds",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    clientLogisticCredentialsIdentifier: credentialsIdentifier
                })
            });

        if (!eachLogisticAPICredsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const eachLogisticAPICredsRespo = await eachLogisticAPICredsResponse.json()
            if (eachLogisticAPICredsRespo.status === "success") {
                setCredentialsData(eachLogisticAPICredsRespo?.response)
            } else {
                if (eachLogisticAPICredsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(eachLogisticAPICredsRespo)
        }
    }

    useEffect(() => {
        eachLogisticAPICreds()
    }, [credentialsIdentifier])

    const generateNewLogisticToken = async () => {
        if (window.confirm(`Do you really want to create a new token?`)) {
            setIsGenerating(true)
            const generateNewLogisticTokenResponse = await fetch(BASE_URL_API + "/generateNewLogisticToken",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: authCtx.token,
                        clientLogisticCredentialsIdentifier: credentialsIdentifier
                    })
                });

            if (!generateNewLogisticTokenResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const generateNewLogisticTokenRespo = await generateNewLogisticTokenResponse.json()
                if (generateNewLogisticTokenRespo.status === "success") {
                    eachLogisticAPICreds()
                } else {
                    if (generateNewLogisticTokenRespo?.message === "token Expired, please login again!") {
                        authCtx.logout()
                    } else {

                    }
                }
                console.log(generateNewLogisticTokenRespo)
            }
            setIsGenerating(false)
        }
    }


    return (
        <div>
            <MainHeader />
            <SettingsBreadcrumb />
            <div className={styles.mainContainer} >
                <div>
                    {credentialsData?.logisticPartnerLabel}
                </div>
                <div>
                    <div className={styles.headerWrapper}>
                        <div>
                            Username : {credentialsData?.username}
                        </div>
                        <div>
                            {isGenerating === true ? <button className={styles.generateTokenBtn} >Generating New Token...</button>
                                :
                                <button className={styles.generateTokenBtn} onClick={() => generateNewLogisticToken()} >Generate New Token</button>
                            }
                        </div>
                    </div>
                    <div>
                        <div className={styles.allTokenTitle}>All Token</div>
                        {credentialsData?.tokens?.length > 0 ?
                            <div>
                                {credentialsData?.tokens?.map((itemToken, indexToken) => (
                                    <div key={indexToken}>
                                        <div className={styles.tagWrapper}>
                                            <div className={styles.generatedAgoTime}>
                                                {humanReadableTimePassed(itemToken?.createdOn)}
                                            </div>
                                            <div className={styles.tag}>
                                                {itemToken?.isActive === "1" ?
                                                    <span className={styles.active} >Active</span>
                                                    :
                                                    <span className={styles.expired} >Expired</span>
                                                }
                                            </div>
                                        </div>
                                        <div className={styles.token}>
                                            <textarea value={itemToken?.token} readOnly ></textarea>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            :
                            <div>
                                No token has been generated yet!
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingsAPIsLogisticsEach