import React, { useState, useEffect } from "react";
import SearchableDropdown from "../../Components/Invoicing/SearchableDropdown";
import styles from "./ClientSearch.module.css";


const ClientSearch = ({ allClients, onSelectClient }) => {
    const [filteredClients, setFilteredClients] = useState([]);

    useEffect(() => {
        setFilteredClients(allClients);
    }, [allClients]);

    return (
        <div className={styles.panelHeader}>
            <SearchableDropdown
                placeholder="Search client by name"
                data={filteredClients}
                filterKey="firmName"
                onItemSelect={onSelectClient}
            />
        </div>
    );
};

export default ClientSearch;
