import React, { useState, useRef } from "react";
import styles from './SearchableDropdown.module.css';

const SearchableDropdown = ({ placeholder, data, filterKey, onItemSelect }) => {
    const [query, setQuery] = useState("");
    const [isFocused, setIsFocused] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);

    const filteredData = data.filter(
        (item) =>
            item[filterKey] &&
            item[filterKey].toLowerCase().includes(query.toLowerCase())
    );

    const inputRef = useRef(null);

    const handleKeyDown = (e) => {
        if (!isFocused) return;

        switch (e.key) {
            case "ArrowDown":
                setHighlightedIndex((prev) =>
                    prev < filteredData.length - 1 ? prev + 1 : 0
                );
                break;
            case "ArrowUp":
                setHighlightedIndex((prev) =>
                    prev > 0 ? prev - 1 : filteredData.length - 1
                );
                break;
            case "Enter":
                if (highlightedIndex >= 0 && highlightedIndex < filteredData.length) {
                    handleSelect(filteredData[highlightedIndex]);
                }
                break;
            case "Escape":
                setIsFocused(false);
                setQuery("");
                break;
            default:
                break;
        }
    };

    const handleSelect = (item) => {
        setQuery(item[filterKey]);
        setIsFocused(false);
        onItemSelect(item);
    };

    return (
        <div
            className={styles.dropdownContainer}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
            tabIndex={0}
        >
            <input
                type="text"
                ref={inputRef}
                placeholder={placeholder}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={handleKeyDown}
                onFocus={() => setIsFocused(true)}
                className={styles.searchInput}
            />
            {isFocused && (
                <div className={styles.suggestionsList}>
                    {filteredData.length > 0 ? (
                        filteredData.map((item, index) => (
                            <div
                                key={index}
                                className={`${styles.suggestionItem} ${
                                    index === highlightedIndex ? styles.highlighted : ""
                                }`}
                                onMouseDown={() => handleSelect(item)} // Prevent onBlur from triggering
                            >
                                {item[filterKey]}
                            </div>
                        ))
                    ) : (
                        <div className={styles.noResults}>No results found</div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchableDropdown;
