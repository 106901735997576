import React, { useContext, useEffect, useRef, useState } from 'react'
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import MainHeader from '../../Components/Headers/MainHeader'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../Store/auth-context'
import styles from './OpBatchProductGrouping.module.css'

const OpBatchProductGrouping = () => {
    const { batchIdentifier } = useParams()
    const authCtx = useContext(AuthContext)
    const [productData, setProductData] = useState([])

    const [columns, setColumns] = useState([200, 200]); // Two resizable columns
    const resizerRefs = useRef([]);

    const [vendorData, setVendorData] = useState([
        {
            productIdentifier: '15',
            vendorsData: [
                {
                    vendorIdentifier: '15',
                    vendorTitle: 'Navneet Chandan',
                    vendorAddress: 'Noida',
                    vendorPincode: '201307',
                    vendorPrice: 650,
                    isSelected: false
                },
                {
                    vendorIdentifier: '24',
                    vendorTitle: 'Navneet',
                    vendorAddress: 'Noida',
                    vendorPincode: '110016',
                    vendorPrice: 550,
                    isSelected: false
                },
            ]
        },
        {
            productIdentifier: '2',
            vendorsData: []
        },
        {
            productIdentifier: '3',
            vendorsData: [
                {
                    vendorIdentifier: '23',
                    vendorTitle: 'Navneet Kumar',
                    vendorAddress: 'Noida',
                    vendorPincode: '201307',
                    vendorPrice: 650,
                    isSelected: false
                },
                {
                    vendorIdentifier: '24',
                    vendorTitle: 'Navneet Chandan',
                    vendorAddress: 'Noida',
                    vendorPincode: '110016',
                    vendorPrice: 550,
                    isSelected: false
                },
            ]
        },
    ])

    // const selectVendorHandler = (productIdentifier, vendorIdentifier) => {
    //     setVendorData((prevVendorData) =>
    //         prevVendorData.map((product) => {
    //             // Check if this product matches the given productIdentifier
    //             if (product.productIdentifier === productIdentifier) {
    //                 return {
    //                     ...product,
    //                     vendorsData: product.vendorsData.map((vendor) => {
    //                         return {
    //                             ...vendor,
    //                             // Toggle selection if the same vendor is clicked, otherwise deselect all others
    //                             isSelected:
    //                                 vendor.vendorIdentifier === vendorIdentifier
    //                                     ? !vendor.isSelected // Toggle the clicked vendor
    //                                     : false, // Deselect all others
    //                         };
    //                     }),
    //                 };
    //             }
    //             return product; // Return other products unchanged
    //         })
    //     );
    // };

    const selectVendorHandler = (productIdentifier, vendorIdentifier) => {
        setVendorData((prevVendorData) =>
            prevVendorData.map((product) => {
                // Check if this product matches the given productIdentifier
                if (product.productIdentifier === productIdentifier) {
                    return {
                        ...product,
                        vendorsData: product.vendorsData.map((vendor) => {
                            return {
                                ...vendor,
                                // Toggle selection only for the clicked vendor
                                isSelected:
                                    vendor.vendorIdentifier === vendorIdentifier
                                        ? !vendor.isSelected // Toggle the clicked vendor
                                        : vendor.isSelected, // Keep others unchanged
                            };
                        }),
                    };
                }
                return product; // Return other products unchanged
            })
        );
    };




    const handleMouseDown = (index) => (e) => {
        e.preventDefault();

        const startX = e.clientX;
        const initialWidths = [...columns];

        const onMouseMove = (e) => {
            const delta = e.clientX - startX;
            const newWidths = [...columns];
            newWidths[index] = Math.max(100, initialWidths[index] + delta); // Minimum width 100px
            setColumns(newWidths);
        };

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };

    const opGroupProducts = async () => {
        const opGroupProductsResponse = await fetch(BASE_URL_API + "/opGroupProducts",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    batchIdentifier: batchIdentifier
                })
            });

        if (!opGroupProductsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const opGroupProductsRespo = await opGroupProductsResponse.json()
            if (opGroupProductsRespo.status === "success") {
                setProductData(opGroupProductsRespo.response)
            } else {
                if (opGroupProductsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(opGroupProductsRespo)
        }
    }

    useEffect(() => {
        opGroupProducts()
    }, [])

    const listVendorHandler = async () => {
        // console.log(productData?.map(product => product?.productIdentifier))
        const opListVendorsForProductsResponse = await fetch(BASE_URL_API + "/opListVendorsForProducts",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    productIdentifiers: productData?.map(product => product?.productIdentifier)
                })
            });

        if (!opListVendorsForProductsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const opListVendorsForProductsRespo = await opListVendorsForProductsResponse.json()
            if (opListVendorsForProductsRespo.status === "success") {
                setVendorData(opListVendorsForProductsRespo?.response)
            } else {
                if (opListVendorsForProductsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(opListVendorsForProductsRespo)
        }
    }

    const updateQuantityHandler = (productIdentifier, vendorIdentifier, quantity) => {
        setVendorData((prevVendorData) =>
            prevVendorData.map((product) => {
                // Check if this product matches the given productIdentifier
                if (product.productIdentifier === productIdentifier) {
                    return {
                        ...product,
                        vendorsData: product.vendorsData.map((vendor) => {
                            // Check if this vendor matches the given vendorIdentifier
                            if (vendor.vendorIdentifier === vendorIdentifier) {
                                return {
                                    ...vendor,
                                    quantity: quantity, // Update the quantity
                                };
                            }
                            return vendor; // Return other vendors unchanged
                        }),
                    };
                }
                return product; // Return other products unchanged
            })
        );
    };
    


    const onUpdate = async (productIdentifier, vendorIdentifier) => {
        console.log(batchIdentifier, productIdentifier, vendorIdentifier)
        const updateVendorForBatchItemsResponse = await fetch(BASE_URL_API+"/updateVendorForBatchItems",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                batchIdentifier : batchIdentifier,
                productData : productIdentifier,
                vendorData : vendorIdentifier
            })
        });

        if(!updateVendorForBatchItemsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateVendorForBatchItemsRespo = await updateVendorForBatchItemsResponse.json();
            if(updateVendorForBatchItemsRespo.status==="success") {

            } else {
                if(updateVendorForBatchItemsRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(updateVendorForBatchItemsRespo)
        }
    }

    const removeVendorFromBatchItems = async (productIdentifier, vendorIdentifier) => {
        console.log(productIdentifier, vendorIdentifier, batchIdentifier)
        const removeVendorFromBatchItemsResponse = await fetch(BASE_URL_API+"/removeVendorFromBatchItems",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                batchIdentifier : batchIdentifier,
                productIdentifier : productIdentifier,
                vendorIdentifier : vendorIdentifier
            })
        });

        if(!removeVendorFromBatchItemsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const removeVendorFromBatchItemsRespo = await removeVendorFromBatchItemsResponse.json();
            if(removeVendorFromBatchItemsRespo.status==="success") {

            } else {
                if(removeVendorFromBatchItemsRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(removeVendorFromBatchItemsRespo)
        }
    }

    const updateVendorAssignment = async () => {
        const updateVendorAssignmentResponse = await fetch(BASE_URL_API+"/updateVendorAssignment",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                batchIdentifier : batchIdentifier
            })
        });

        if(!updateVendorAssignmentResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateVendorAssignmentRespo = await updateVendorAssignmentResponse.json()
            if(updateVendorAssignmentRespo.status==="success") {

            } else {
                if(updateVendorAssignmentRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(updateVendorAssignmentRespo)
        }
    }

    return (
        <div>
            <MainHeader />
            <div className={styles.container}>
                <div className={styles.filterWrapper}>
                    <div>
                        <button onClick={() => listVendorHandler()} >List Vendor</button>
                    </div>
                    <div className={styles.nextBtn} >
                        <button onClick={() => updateVendorAssignment()} >Update Vendor Assignment</button>
                        <Link to={`/marketplace/order-processing/batch/${batchIdentifier}/shipping`}>Go To Shipping</Link>
                    </div>
                </div>
                <div className={styles.table} style={{ height: '80vh' }}>
                    <div className={styles.header}>
                        {['Product Info', 'Vendors', 'Assigned Vendors'].map((header, index) => (
                            <div
                                key={index}
                                className={`${styles.column} ${index === 2 ? styles.flexGrow : ''
                                    }`}
                                style={index < 2 ? { width: columns[index] } : {}}
                            >
                                {header}
                                {index < 2 && (
                                    <div
                                        ref={(el) => (resizerRefs.current[index] = el)}
                                        className={styles.resizer}
                                        onMouseDown={handleMouseDown(index)}
                                    ></div>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className={styles.body}>
                        {productData?.map((row, rowIndex) => (
                            <div key={rowIndex} className={styles.row}>
                                <div
                                    className={styles.cell}
                                    style={{ width: columns[0] }}
                                >
                                    {row.productTitle} ({row?.itemCount})
                                </div>
                                <div
                                    className={styles.cell}
                                    style={{ width: columns[1] }}
                                >
                                    <div className={styles.vendorListingWrapper}>
                                        {vendorData?.find((item) => item.productIdentifier === row?.productIdentifier) ? (
                                            vendorData
                                                .find((item) => item.productIdentifier === row?.productIdentifier)
                                                ?.vendorsData?.length > 0 ? (
                                                vendorData
                                                    .find((item) => item.productIdentifier === row?.productIdentifier)
                                                    ?.vendorsData?.map((vendor, index) => (
                                                        <div key={vendor.vendorIdentifier || index} className={styles.eachVendorWrapper}
                                                            onClick={() => selectVendorHandler(row?.productIdentifier, vendor?.vendorIdentifier)}
                                                        >
                                                            <div>
                                                                {vendor?.isSelected === true ?
                                                                    <MdRadioButtonChecked size={28} />
                                                                    :
                                                                    <MdRadioButtonUnchecked size={28} />
                                                                }
                                                            </div>
                                                            <div>
                                                                <div className={styles.vendorTitle}>
                                                                    {vendor?.vendorTitle}
                                                                </div>
                                                                <div className={styles.vendorPrice}>
                                                                    ₹{vendor?.vendorPrice}
                                                                </div>
                                                                <div>
                                                                    ₹{parseFloat(vendor?.vendorPrice) * parseFloat(row?.itemCount)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                            ) : (
                                                <div>No vendors available for this product.</div>
                                            )
                                        ) : (
                                            <div>Product not found.</div>
                                        )}
                                    </div>

                                </div>
                                <div className={`${styles.cell} ${styles.flexGrow}`}>
                                    {row.column3}
                                    <div>
                                        {vendorData
                                            ?.filter((item) => item.productIdentifier === row.productIdentifier)
                                            ?.map((itemSv) =>
                                                itemSv.vendorsData
                                                    .filter((vendor) => vendor.isSelected) // Only selected vendors
                                                    .map((selectedVendor, index) => (
                                                        <div key={index} className={styles.eachSelectedVendor}>
                                                            <div className={styles.esvDetails}>
                                                                <div className={styles.esvTitle}>
                                                                    {selectedVendor.vendorTitle}
                                                                </div>
                                                                {selectedVendor.vendorAddress}
                                                            </div>
                                                            <div className={styles.quantity}>
                                                                <input placeholder="Quantity" value={selectedVendor?.quantityAlloted} type="number" onChange={(e) => updateQuantityHandler(row?.productIdentifier, selectedVendor?.vendorIdentifier, e.target.value)} />
                                                            </div>
                                                            <div className={styles.updateBtn}>
                                                                <button onClick={() => removeVendorFromBatchItems(row?.productIdentifier, selectedVendor?.vendorIdentifier)} >Remove</button>
                                                                <button onClick={() => onUpdate(row, selectedVendor)} >Update</button>
                                                            </div>
                                                        </div>
                                                    ))
                                            )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default OpBatchProductGrouping