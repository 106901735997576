import React from 'react';
import styles from './EachPDS.module.css';

const EachPDS = (props) => {
    const { isOpen, onClose, data, initiateCampaignLaunch } = props;

    if (!isOpen) {
        return null;
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer} onClick={onClose} >
                    <div className={styles.mainContent}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className={styles.mcHeader}>
                            <div className={styles.title}>
                                {data?.title}
                            </div>
                            <button className={styles.closeBtn} onClick={() => onClose()} >Close</button>
                        </div>
                        <div className={styles.mainContentBody} >
                            <div className={styles.definition}>
                                {data?.definition}
                            </div>
                            <div className={styles.sectionWrapper}>
                                <div className={styles.sectionTitle} >Suitable For : </div>
                                <ul className={styles.applicableFor}>
                                    {data?.applicableFor?.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            <div className={styles.sectionWrapper}>
                                <div className={styles.sectionTitle} >Logic & Rules : </div>
                                <div className={styles.sectionData} >
                                    {
                                        Object.entries(data.logicAndRules)?.map(([key, value], index) => (
                                            <div className={styles.eachLnR} key={index} >
                                                <div className={styles.key} > {key} </div>
                                                <div className={styles.value} > {value}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className={styles.sectionWrapper}>
                                <div className={styles.sectionTitle} >Use cases : </div>
                                <div className={styles.example}>
                                    <ul>
                                        <li>
                                            {data?.example}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className={styles.mcFooter}>
                            <button className={styles.launchBtn} onClick={() => initiateCampaignLaunch(data)} >Launch</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default EachPDS;
