import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';
import styles from './ELPDSPointAllocation.module.css';

const ELPDSPointAllocation = ({ isOpen, close, selectedUser, clientIdentifier, pdsCampaignIdentifier }) => {
    const authCtx = useContext(AuthContext);
    const [points, setPoints] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const allocatePointNow = async (e) => {
        if (window.confirm(`Are you sure you want to allocate points?`)) {
            e.preventDefault(); // Prevent form submission default behavior
            setIsLoading(true); // Set loading state to true

            try {
                const response = await fetch(`${BASE_URL_API}/allocatePointNow`, {
                    method: 'POST',
                    body: JSON.stringify({
                        token: authCtx.token,
                        points: points,
                        userData: selectedUser,
                        clientIdentifier: clientIdentifier,
                        pdsCampaignIdentifier: pdsCampaignIdentifier
                    }),
                });

                if (!response.ok) {
                    throw new Error('Server Error');
                }

                const data = await response.json();

                if (data.status === 'success') {
                    // Add success handling logic if needed
                    toast('Points allocated successfully!');
                    close()
                } else {
                    if (data.message === 'token Expired, please login again!') {
                        authCtx.logout();
                    } else {
                        toast(data.message || 'Something went wrong!');
                    }
                }
                console.log(data)
            } catch (error) {
                console.error('Error allocating points:', error.message);
            } finally {
                setIsLoading(false); // Reset loading state
            }
        }
    };

    if (!isOpen) {
        return null
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer} onClick={() => close()}>
                <div className={styles.mainContent} onClick={(e) => e.stopPropagation()}>
                    <div className={styles.title}>Allocate Points</div>
                    <form onSubmit={allocatePointNow}>
                        <div className={styles.mainLine}>
                            <span>Allocate</span>
                            <input
                                type="number"
                                autoFocus
                                placeholder="Number of points"
                                value={points}
                                onChange={(e) => setPoints(e.target.value)}
                                required
                            />
                            <span>points to each selected {selectedUser?.length} user.</span>
                        </div>
                        <div className={styles.actionBtn}>
                            <button type="submit" disabled={isLoading}>
                                {isLoading ? 'Allocating...' : 'Allocate Now'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ELPDSPointAllocation;
