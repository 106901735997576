import React from 'react'
import { FaShippingFast } from 'react-icons/fa'
import { MdApi, MdCloud, MdOutlineInsertComment } from 'react-icons/md'
import { Link } from 'react-router-dom'
import MainHeader from '../../Components/Headers/MainHeader'
import SettingsBreadcrumb from '../../Components/Settings/SettingsBreadcrumb'
import styles from './SettingsAPIs.module.css'

const SettingsAPIs = () => {

    const handleApiRecordSubmit = () => {

    }
    return (
        <div>
            <MainHeader />
            <SettingsBreadcrumb />
            <div className={styles.mainContainer}>
                <div className={styles.eachSectionWrapper}>
                    <div className={styles.sectionHeader}>
                        <div className={styles.sectionTitle}><MdApi /> API Credentials</div>
                        <div className={styles.sectionSubtitle}>
                            Manage all your API Integrations here...
                        </div>
                    </div>
                    <div className={styles.sectionBody}>
                        <Link to={`/settings/apis/logistics`} className={styles.eachSubSectionWrapper}>
                            <div className={styles.subSectionTitle} ><FaShippingFast /> Logistic</div>
                            <div className={styles.optionsWrapper}>

                            </div>
                        </Link>

                        <Link to={`/settings/apis/communications`} className={styles.eachSubSectionWrapper}>
                            <div className={styles.subSectionTitle} ><MdOutlineInsertComment /> Communication</div>
                            <div className={styles.optionsWrapper}>

                            </div>
                        </Link>

                        <Link to={`/settings/apis/cdn-storage`} className={styles.eachSubSectionWrapper}>
                            <div className={styles.subSectionTitle} ><MdCloud /> CDN Storage</div>
                            <div className={styles.optionsWrapper}>

                            </div>
                        </Link>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default SettingsAPIs