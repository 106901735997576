import React from 'react'
import styles from './ELPDSDetails.module.css'

const ELPDSDetails = () => {
    return (
        <div>
            <div className={styles.statsWrapper}>
                <div className={styles.eachStats}>
                    <div>
                        Total Points Allocated :
                    </div>
                    <div>-</div>
                </div>
                <div className={styles.eachStats}>
                    <div>
                        Total Points Redemmed :
                    </div>
                    <div>-</div>
                </div>
                <div className={styles.eachStats}>
                    <div>
                        Total Users Enrolled :
                    </div>
                    <div>-</div>
                </div>
            </div>
            <div>

            </div>
        </div>
    )
}

export default ELPDSDetails