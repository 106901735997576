import React, { useState } from 'react';
import { MdError } from 'react-icons/md';
import styles from './ErrorTooltip.module.css';

const ErrorTooltip = ({ itemLog }) => {
    const [visibleErrorIndex, setVisibleErrorIndex] = useState(null);

    const handleMouseEnter = (index) => {
        setVisibleErrorIndex(index);
    };

    const handleMouseLeave = () => {
        setVisibleErrorIndex(null);
    };

    const handleFocus = (index) => {
        setVisibleErrorIndex(index);
    };

    const handleBlur = () => {
        setVisibleErrorIndex(null);
    };

    // Ensure `errors` is treated as an array or a string
    const errors = Array.isArray(itemLog?.courierResponse?.errors)
        ? itemLog?.courierResponse?.errors
        : typeof itemLog?.courierResponse?.errors === 'string'
            ? [itemLog?.courierResponse?.errors]
            : itemLog?.courierResponse?.errors
                ? [itemLog?.courierResponse?.errors]
                : [];

    const formatError = (error) => {
        if (typeof error === 'string') {
            return error;
        } else if (typeof error === 'object') {
            return JSON.stringify(error, null, 2); // Format object as a string
        }
        return '';
    };

    return (
        <div
            className={styles.errorContainer}
            onMouseEnter={() => handleMouseEnter(itemLog.id)}
            onMouseLeave={handleMouseLeave}
            onFocus={() => handleFocus(itemLog.id)}
            onBlur={handleBlur}
            tabIndex={0} // To make the div focusable
        >
            <MdError />
            {visibleErrorIndex === itemLog.id && errors.length > 0 && (
                <div className={styles.errorTooltip}>
                    {errors.map((error, idx) => (
                        <div key={idx}>{formatError(error)}</div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ErrorTooltip;
