import React, { useContext, useEffect, useState } from 'react';
import { FaFilter } from 'react-icons/fa6';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { Link } from 'react-router-dom';
import MainHeader from '../../Components/Headers/MainHeader';
import EachRowAllProducts from '../../Components/Marketplace/EachRowAllProducts';
import EditProduct from '../../Components/Marketplace/EditProduct';
import { BASE_URL_API } from '../../References/urls';
import AuthContext from '../../Store/auth-context';
import styles from './ViewProducts.module.css';

const ViewProducts = () => {
    const authCtx = useContext(AuthContext);
    const [allProducts, setAllProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [editingItem, setEditingItem] = useState(null);
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [searchKey, setSearchKey] = useState('');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [filters, setFilters] = useState({
        brand: '',
        category: '',
        subCategory: '',
    });

    const editingItemHandler = (k) => {
        setEditingItem(k);
    };

    const closeEditingItem = () => setEditingItem(null);

    const getEcomProducts = async () => {
        setIsLoading(true);
        const response = await fetch(BASE_URL_API + '/getEcomProducts', {
            method: 'POST',
            body: JSON.stringify({ token: authCtx.token }),
        });

        if (!response.ok) {
            console.log('Something went wrong : Server Error');
        } else {
            const data = await response.json();
            if (data.status === 'success') {
                setAllProducts(data.response);
                setFilteredData(data.response);
            } else if (data.message === 'token Expired, please login again!') {
                authCtx.logout();
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        getEcomProducts();
    }, []);

    const filterData = () => {
        let filtered = allProducts;

        if (filters.brand) {
            filtered = filtered.filter((item) => item.brand === filters.brand);
        }
        if (filters.category) {
            filtered = filtered.filter((item) => item.category === filters.category);
        }
        if (filters.subCategory) {
            filtered = filtered.filter((item) => item.subCategory === filters.subCategory);
        }
        if (searchKey) {
            filtered = filtered.filter((item) =>
                Object.values(item).some((value) =>
                    value?.toString().toLowerCase().includes(searchKey.toLowerCase())
                )
            );
        }
        setFilteredData(filtered);
    };

    useEffect(() => {
        filterData();
    }, [searchKey, filters]);

    const handleSelectAll = () => {
        if (selectedProducts.length === filteredData.length) {
            setSelectedProducts([]);
        } else {
            setSelectedProducts(filteredData.map((item) => item?.productIdentifier));
        }
    };

    const handleSelectItem = (id) => {
        if (selectedProducts.includes(id)) {
            setSelectedProducts(selectedProducts.filter((itemId) => itemId !== id));
        } else {
            setSelectedProducts([...selectedProducts, id]);
        }
    };

    const updateFilter = (key, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [key]: value,
        }));
    };

    return (
        <div>
            <MainHeader />
            {!!editingItem && (
                <EditProduct
                    onCompletion={getEcomProducts}
                    isOpen={!!editingItem}
                    item={editingItem}
                    closeEditingItem={closeEditingItem}
                />
            )}
            {isFilterVisible && (
                <div className={styles.filterWrapper}>
                    <div className={styles.filterContainer} id="mcmsfjhd43ds">
                        <div className={styles.filterContent}>
                            <div className={styles.filters}>
                                <div>
                                    <select onChange={(e) => updateFilter('brand', e.target.value)}>
                                        <option value="">Select Brand</option>
                                        {/* Add options dynamically */}
                                    </select>
                                </div>
                                <div>
                                    <select onChange={(e) => updateFilter('category', e.target.value)}>
                                        <option value="">Select Category</option>
                                        {/* Add options dynamically */}
                                    </select>
                                </div>
                                <div>
                                    <select onChange={(e) => updateFilter('subCategory', e.target.value)}>
                                        <option value="">Select Sub Category</option>
                                        {/* Add options dynamically */}
                                    </select>
                                </div>
                            </div>
                            <div className={styles.resultRow}>
                                Showing {filteredData?.length} / {allProducts?.length} results
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className={styles.headerWrapper}>
                <div className={styles.searchBar}>
                    <input
                        placeholder="Search for anything..."
                        onChange={(e) => setSearchKey(e.target.value)}
                    />
                </div>
                <div className={styles.headerRightWrapper}>
                    <button onClick={() => setIsFilterVisible(true)} className={styles.filterBtn}>
                        <FaFilter size={18} color="rebeccapurple" />
                    </button>
                    <Link to={`/marketplace/add-products-in-bulk`} className={styles.addBtn}>
                        Add Product In Bulk
                    </Link>
                </div>
            </div>
            <div className={styles.mainContentWrapper}>
                {isLoading ? (
                    <div>Hold on! Collecting all products!</div>
                ) : (
                    <div>
                        <div className={styles.tableHeader}>
                            <div className={styles.selector} onClick={handleSelectAll}>
                                {selectedProducts.length === filteredData.length ? (
                                    <MdCheckBox size={22} />
                                ) : (
                                    <MdCheckBoxOutlineBlank size={20} />
                                )}
                            </div>
                            <div className={styles.id}>#</div>
                            <div className={styles.title}>Title</div>
                            <div className={styles.brand}>Brand</div>
                            <div className={styles.category}>Category</div>
                            <div className={styles.description}>Description</div>
                            <div className={styles.pointsRequired}>Points Required</div>
                            <div>Action</div>
                        </div>
                        <div className={styles.tableBody}>
                            {filteredData?.map((item, index) => (
                                <EachRowAllProducts
                                    key={index}
                                    item={item}
                                    editingItemHandler={editingItemHandler}
                                    getEcomProducts={getEcomProducts}
                                    isSelected={selectedProducts.includes(item?.productIdentifier)}
                                    handleSelectItem={handleSelectItem}
                                />
                            ))}
                            <div className={styles.thatsAll}>That's all!</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ViewProducts;
